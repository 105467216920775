import { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode'; 

const useAuth = () => {
  const isTokenValid = (token) => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp >= currentTime) {
          return true;
        } else {
          localStorage.removeItem('SecureHashtoken');
        }
      } catch (error) {
        console.error('Invalid token', error);
        localStorage.removeItem('SecureHashtoken');
      }
    }
    return false;
  };

  

  const initialAuthState = isTokenValid(localStorage.getItem('SecureHashtoken'));
  const [isAuthenticated, setIsAuthenticated] = useState(initialAuthState);

  useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');
    setIsAuthenticated(isTokenValid(token));
  }, []);

  return isAuthenticated;
};

export default useAuth;
