import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const Redirector = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {
    const getQueryParam = (name) => { 
      const urlParams = new URLSearchParams(location.search);
      return urlParams.get(name);
    };

    const token = getQueryParam('t');
    
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const isExpired = decodedToken.exp * 1000 < Date.now();
        
        if (!isExpired) {
          localStorage.setItem('SecureHashtoken', token);
          navigate('/dashboard');
        } else {

          navigate('/login'); 
        }
      } catch (error) {
        console.error('Invalid token', error);
        navigate('/login'); 
      }
    } else {
      console.error('Token not found in URL');
      navigate('/login'); 
    }
  }, [location.search, navigate]);

  return null;  
};

export default Redirector;
