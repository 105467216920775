import { useState, useEffect } from 'react';

const useTFA = () => {
  const isTFAValid = (TFA) => {
    if (TFA) {
     return true;
    }
    return false;
  };

  

  const initialTFAState = isTFAValid(localStorage.getItem('tfaChallenge'));
  const [isTFA, setIsTFA] = useState(initialTFAState);

  useEffect(() => {
    const TFA = localStorage.getItem('tfaChallenge');
    setIsTFA(isTFAValid(TFA));
  }, []);

  return isTFA;
};

export default useTFA;
