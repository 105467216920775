import React, { useState, useEffect, useRef  } from 'react';
import { FaUserCircle, FaCreditCard, FaBell, FaLock,  FaEye, FaEyeSlash,FaCog, FaCoins } from 'react-icons/fa';
import StTabStyles from './SettingsTab.module.css';
import { Link,  useNavigate } from 'react-router-dom';
import axios from 'axios';
import en from '../locales/en.json';
import es from '../locales/es.json';
import ar from '../locales/ar.json';
import ru from '../locales/ru.json';
import hi from '../locales/hi.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import {Alert, TextField, Button, Box, Typography, Modal,} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { QrCode } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import URL from '../hooks/URL';



const SettingsTabs = () => {
const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('profile');
  const [error, setError] = useState('');
  const [editProfile, setEditProfile] = useState(false);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [passwords, setPasswords] = useState({ newPassword: '', confirmPassword: '', oldPassword: '' });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [TFAStatus, setTFAStatus] = useState('');
  const [Enable2FA, setEnable2FA] = useState(false);
  const [language, setLanguage] = useState('en');
  const [text, setText] = useState(en);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light' );
  const [profileMessage, setProfileMessage] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [modal2FAOpen, setModal2FAOpen] = useState(false);
  const [qrcode, setQrCode]= useState('');
  const [secretKey, setSecretKey] = useState('');
  const ref = useRef(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertOpen2, setAlertOpen2] = useState(false);
  const [processTFA, setProcessTFA] = useState(false);
  const [successMessage,setSuccessMessage] = useState('');
  const [totp, setTOtp] = useState(new Array(6).fill(""));
  const [LoginTime, setLoginTime] =useState([]);
  const [isFormUser, setIsFormUser] = useState(false);
  const [EmailNotify, setEmailNotify] = useState(false);
  const [AppNotify, setAppNotify] = useState(false);


  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  
  const handleAlertClose2 = () => {
    setAlertOpen2(false);
  };
  


  const [openToast, setToastOpen] = useState(false);

  const handleToastClick = () => {
    setToastOpen(true);
  };

  const handleToastClose = () => {

    setToastOpen(false);
  };

  const handleTFAClick = () => {
      setProcessTFA(true);
  };

  
  const handleTFASubmit = async () => {
    
    setSuccessMessage('');
    const token = localStorage.getItem('SecureHashtoken');
    const totp1=  totp.join('');
    const response = await axios.post(URL()+"TFAChallenge", {
      totp: totp1
    },
    { headers: { Authorization: `Bearer ${token}` } });

    if(response.data.codevalue == true)
    {
      setProcessTFA(false);
      setModal2FAOpen(false);
      setSuccessMessage('OTP Verified, Two Factor Verification Setup!');
      setAlertOpen2(true);
      setEnable2FA(true);
      setTFAStatus(true);
    }
    else{
      setErrorPassword("Incorrect OTP, Please try again!");
    setAlertOpen(true);
      
    }


};


const handleDisableTFASubmit = async () => {
    
  setSuccessMessage('');
  const token = localStorage.getItem('SecureHashtoken');
  const totp1=  totp.join('');
  const response = await axios.post(URL()+"DisableTFAChallenge", {
    totp: totp1
  },
  { headers: { Authorization: `Bearer ${token}` } });

  if(response.data.codevalue == true)
  {
    setProcessTFA(false);
    setModal2FAOpen(false);
    setSuccessMessage('OTP Verified, Two Factor Verification Disabled!');
    setAlertOpen2(true);
    setEnable2FA(false);
    setTFAStatus(false);
  }
  else{
    setErrorPassword("Incorrect OTP, Please try again!");
  setAlertOpen(true);
    
  }


};



  const [planmodalOpen, setPlanModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleModalOpen = () => setPlanModalOpen(true);
  const handleModalClose = () => setPlanModalOpen(false);

  const Modal2Open = () => setModal2FAOpen(true);
  const Modal2Close = () => setModal2FAOpen(false);

  const handlePlanSelect = (plan) => {
    console.log(plan);
    setSelectedPlan(plan);
  };

  const plans = [
    { name: 'Basic Plan', credits: '100 Credits', price: '$10' },
    { name: 'Standard Plan', credits: '500 Credits', price: '$40' },
    { name: 'Premium Plan', credits: '1000 Credits', price: '$65' }
  ];



  const handleLanguageChange = () => {
 
    localStorage.setItem('DashBoardLanguage', language);
    localStorage.setItem('theme', theme);
  if(theme === 'dark'){

    if(!document.body.classList.contains('dark-theme')){
    document.body.classList.add('dark-theme');
    }
  }
  else
  {
    document.body.classList.remove('dark-theme');
  }
   // document.body.className = theme === 'dark' ? 'dark-theme' : '';
    switch(language) {
      case 'es':
        setText(es);
        break;
      case 'ar':
        setText(ar);
        break;
      case 'ru':
        setText(ru);
        break;
      case 'hi':
        setText(hi);
        break;
      case 'pt':
        setText(pt);
        break;
      case 'id':
        setText(id);
      break;
   
      default:
        setText(en);
    }
  };


useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');
    let lang = localStorage.getItem('DashBoardLanguage');
    var theme2 = localStorage.getItem('theme') ;
    const FormUser = localStorage.getItem('FormUser');
    if(FormUser)
    {
        setIsFormUser(true);
    }

    if (!token) {
      navigate('/'); 
    } else {
      if(!lang)
      {
        lang = 'en';
      }
      else{
        setLanguage(lang);
      }

      if(!theme2)
      {
        theme2 = 'light';
      }
      else{
        setTheme(theme2);
      }
        switch(lang) {
          case 'es':
            setText(es);
            break;
          case 'ar':
            setText(ar);
            break;
          case 'ru':
            setText(ru);
            break;
          case 'hi':
            setText(hi);
            break;
          case 'id':
            setText(id);
            break;
          case 'pt':
            setText(pt);
            break;
          default:
            setText(en);
        }
        if(theme2 === 'dark'){
          console.log(document.body.classList.contains('dark-theme'));
          if(!document.body.classList.contains('dark-theme')){
          document.body.classList.add('dark-theme');
          }
        }
        else
        {
          document.body.classList.remove('dark-theme');
        }

      fetchUserData(token);
    }
  }, []);


  const fetchUserData = async (token) => {
    try {
      const response =  await axios.post(
        URL()+'user',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setName(response.data.name); // Set user's name from response
      setEmail(response.data.email);
      setTFAStatus(response.data.TFA == 1 ? "Enabled" : "Disabled");
      setEnable2FA(response.data.TFA == 1 ? true : false);
      setLoginTime(response.data.login_history.map(history => history.login_time));
      setEmailNotify(response.data.Email_Notify == 1 ? true : false);
      setAppNotify(response.data.App_Notify == 1 ? true : false);
      if (!response.data.success) {
        throw new Error('Failed to fetch user data');
      }

      
    } catch (error) {
    //   console.error('Error fetching user data:', error.message);
    //   navigate('/'); // Redirect to login on error
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const [cpasswordVisible, setCPasswordVisible] = useState(false);

  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(!cpasswordVisible);
  };

  const [opasswordVisible, setOldPasswordVisible] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setOldPasswordVisible(!opasswordVisible);
  };




  const handleProfileUpdate = async (e) => {
    const name  = e.currentTarget.value;

    if(name === text.update_profile)
        {
            if(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(email))
            {
  
              const token = localStorage.getItem('SecureHashtoken');
                setError('');
                setErrorPassword('');
                setProfileMessage('');
                try {
                  const response =  await axios.post(
                    URL()+'checkmailup',
                    {email: email},
                    { headers: { Authorization: `Bearer ${token}` } }
                  );
                  console.log(response.data.message)
                    if(response.data.message == "Eligble to change.")
                    {
                      setUpdateProfile(true);
                      try {
                        const response =  await axios.post(
                          URL()+'sendup-otp',
                          {email: email},
                          { headers: { Authorization: `Bearer ${token}` } }
                        );
                        console.log(response.data.message)
                          
                        
                      } catch (error) {
                        console.error('Error sending otp', error.message);
                      //   navigate('/'); // Redirect to login on error
                      }
                    }
                    else{
                      setProfileMessage(response.data.message);
                    }
                  
                } catch (error) {
                  console.error('Error sending otp', error.message);
                //   navigate('/'); // Redirect to login on error
                }
                
                
                 
                 
            }
            else{
                setError('Invalid email address.');
                setErrorPassword('Invalid email address.');
                setAlertOpen(true);
            }
        }
      
    else {
     
       setUpdateProfile(false);
       if(!editProfile){
       setEditProfile(true);
       }
       else{
        
           setEditProfile(false);
       }  

    
          
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePassword = async () => {
  
   
  //  setPasswords({ newPassword: '', confirmPassword: '' , oldPassword: ''});
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(passwords.newPassword)) {
        setErrorPassword('Password must be at least 8 characters long and contain both upper and lower case letters.');
        setAlertOpen(true);
        return;
      }

      if (passwords.newPassword !== passwords.confirmPassword) {
        setErrorPassword('Passwords do not match');
        setAlertOpen(true);
        return;
      }

      const token = localStorage.getItem('SecureHashtoken');
      try {
       const response =  await axios.post(
         URL()+'changepassword',
         {oldpassword: passwords.oldPassword, newpassword: passwords.newPassword},
         { headers: { Authorization: `Bearer ${token}` } }
       );
      
       if(response.data.message.includes('Password has been')){
        setErrorPassword('');
        setModalOpen(false);
        setProfileMessage(response.data.message);
       }
       else{
        setErrorPassword(response.data.message)
       }
  
      } catch (error) {
        console.error('Error sending otp', error.message);
        setErrorPassword("Old password doesn't match")
        setAlertOpen(true);
      //   navigate('/'); // Redirect to login on error

      }
    
    

  };
  
  const updateNotifications = async () => {
    const token = localStorage.getItem('SecureHashtoken');
    try {
      const response = await axios.post(
        URL()+'update-notifications',
        {EmailNotify: EmailNotify ? 1 : 0, AppNotify: AppNotify ? 1 : 0},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setSuccessMessage(response.data.message);
      setAlertOpen2(true);
    }catch (error) {
        console.error('Error updating notifications', error.message);
      }
  }

  const click2FA = async () => {
    if(Enable2FA)
        {
          setProcessTFA(true);
            setModal2FAOpen(true);
           
        }
        else{
          const token = localStorage.getItem('SecureHashtoken');
          try {
            const response = await axios.post(
              URL()+'generate2fa',
              {},
              { headers: { Authorization: `Bearer ${token}` } }
            );
            setQrCode(response.data.qrcode);
            setSecretKey(response.data.TFA_Key);
            // setEnable2FA(true);
          } catch (error) {
            console.error('Error enabling 2FA', error.message);
          }
          setModal2FAOpen(true);
           
        }
  }

  const submitProfileVerify = async () => {
    const token = localStorage.getItem('SecureHashtoken');
    try {
     const response =  await axios.post(
       URL()+'verifyup-otp',
       {email: email, name: name, otp: otp.join('')},
       { headers: { Authorization: `Bearer ${token}` } }
     );
     console.log(response);
     setProfileMessage(response.data.message);
     if(response.data.message.includes('Profile')){
     setUpdateProfile(false);
     setEditProfile(false);
     }

    } catch (error) {
      console.error('Error sending otp', error.message);
    //   navigate('/'); // Redirect to login on error
    setProfileMessage('Incorrect OTP. Please try again.');
    }
   
  }

  const [otp, setOtp] = useState(new Array(6).fill(""));


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const value = element.value;
    setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);

    if (value) {
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    } else {
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    }
  };

  const handleKeyDown = (element, index) => {
    if (element.key === "Backspace" && otp[index] === "") {
      if (element.target.previousSibling) {
        element.target.previousSibling.focus();
      }
    }
  };

  const handleEmailNotify = (e) => {
    setEmailNotify(e.target.checked);
  };

  const handleAppNotify = (e) => {
    setAppNotify(e.target.checked);
  };

  const handleTChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const value = element.value;
    setTOtp([...totp.map((d, idx) => (idx === index ? value : d))]);

    if (value) {
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    } else {
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    }
  };

  const handleKeyTDown = (element, index) => {
    if (element.key === "Backspace" && totp[index] === "") {
      if (element.target.previousSibling) {
        element.target.previousSibling.focus();
      }
    }
  };



  return (
    <div className={StTabStyles.settingsContainer}>
      <div className={StTabStyles.settingsTabs}>
        <div 
          className={`${StTabStyles.settingsTab} ${activeTab === 'profile' ? StTabStyles.active : ''}`} 
          onClick={() => setActiveTab('profile')}
        >
          <FaUserCircle />
          <span>{text.profile}</span>
        </div>
        {/* <div 
          className={`${StTabStyles.settingsTab} ${activeTab === 'subscription' ? StTabStyles.active : ''}`} 
          onClick={() => setActiveTab('subscription')}
        >
          <FaCreditCard />
          <span>{text.subscription}</span>
        </div> */}
        <div 
          className={`${StTabStyles.settingsTab} ${activeTab === 'notifications' ? StTabStyles.active : ''}`} 
          onClick={() => setActiveTab('notifications')}
        >
          <FaBell />
          <span>{text.notifications}</span>
        </div>
        <div 
          className={`${StTabStyles.settingsTab} ${activeTab === 'security' ? StTabStyles.active : ''}`} 
          onClick={() => setActiveTab('security')}
        >
          <FaLock />
          <span>{text.security}</span>
        </div>
        {/* <div 
          className={`${StTabStyles.settingsTab} ${activeTab === 'billing' ? StTabStyles.active : ''}`} 
          onClick={() => setActiveTab('billing')}
        >
          <FaCreditCard />
          <span>{text.billing}</span>
        </div> */}
        {/* <div 
          className={`${StTabStyles.settingsTab} ${activeTab === 'preferences' ? StTabStyles.active : ''}`} 
          onClick={() => setActiveTab('preferences')}
        >
          <FaCog />
          <span>{text.preferences}</span>
        </div> */}
      </div>
      <div className={`${StTabStyles.settingsContent} ${modalOpen ? StTabStyles.blur : ''}`} >
      {activeTab === 'profile' && (
        
        <div style={isFormUser ? null : { backgroundColor: 'rgba(0, 0, 0, 0.1)', backdropFilter: 'blur(15px)', filter: 'blur(15px)' }}>
            <h2>{text.profile_settings}</h2>
            {updateProfile ? (<><div style={{display: "flex"}}> <label className={StTabStyles.otpLabel}>{text.otp_label}</label></div>
            <div className={StTabStyles.otpInputs}>
            {otp.map((data, index) => {
                return (
                  <input
                    className={StTabStyles.otpField}
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  
                  />
                );
              })}
              <Link>{text.resend}</Link>
            </div>
            <button style={{marginTop:"0px"}} onClick={submitProfileVerify} className={StTabStyles.btnPrimary3}><b>{text.submit}</b></button>
            </> ) : (
                <div>
            {editProfile ? (
              <div>
                {/* <label>{text.name}:</label><br /> */}
                <TextField variant='outlined' key='Name' label='Name' sx={{width: 'calc(50% - 70px)',  '@media screen and (max-width: 768px)': {
                   
                   width: 'calc(100% - 70px) !important'
                                 }}} type="text" value={name} onChange={(e) => setName(e.target.value)} maxLength={50} autoComplete="off" />
                <br /><br />
                <TextField variant='outlined' key='Email' label='Email' sx={{width: 'calc(50% - 70px)', padding: '0 !important', margin: '0 !important', "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important', padding: "16.5px 14px"
               }, '@media screen and (max-width: 768px)': {
                   
                width: 'calc(100% - 70px) !important'
                              }}}  type="email" value={email} onChange={(e) => setEmail(e.target.value)} maxLength={50}  autoComplete="off" />
              </div>
            ) : (
              <div>
                <p>{text.name}: {name}</p>
                <p>{text.email}: {email}</p> 
              </div>
            )}
             {/* {error && <Alert severity='error' className={StTabStyles.errorText}>{error}</Alert>} */}
            <Button variant='contained' onClick={ isFormUser ? handleProfileUpdate : null} className={StTabStyles.btnPrimary3} value={`${editProfile ? text.update_profile : text.edit_profile}`} ><b> {editProfile ? (text.update_profile)  : text.edit_profile }</b></Button>
            <Button variant='contained' onClick={() => isFormUser && setModalOpen(true)} className={StTabStyles.btnPrimary3}><b>{text.change_password}</b></Button>
        
        </div>
        )}
        {profileMessage != "" ? (<Alert severity={profileMessage.includes('Incorrect') || profileMessage.includes('already exists') ? 'error' : 'success'} >{profileMessage}</Alert>) : <></> }
          </div>
        )}
        {activeTab === 'subscription' && (
          <div>
            <h2>{text.subscription_settings}</h2>
            <p>Current Plan: Basic</p>
            <Button variant='contained' className={StTabStyles.btnPrimary3} onClick={handleModalOpen}><b>{text.upgrade_plan}</b></Button>
            <p>Payment Method: Visa **** **** 1234</p>
          </div>
        )}
        {activeTab === 'notifications' && (
          <div>
            <h2>{text.notification_settings}</h2>
            <label className={StTabStyles.checkboxContainer}>
      <input type="checkbox" required name="EmailNotifications" checked={EmailNotify} onChange={handleEmailNotify} />
      <span className={StTabStyles.customCheckbox}></span>
      <span className={StTabStyles.labelText}>{text.email_notifications}</span>
    </label>

            <label className={StTabStyles.checkboxContainer}>
      <input type="checkbox" required name="In-AppNotifications"  checked={AppNotify} onChange={handleAppNotify}  />
      <span className={StTabStyles.customCheckbox}></span>
      <span className={StTabStyles.labelText}>{text.in_app_notifications}</span>
    </label>
    <Button variant='contained' className={StTabStyles.btnPrimary3} onClick={updateNotifications}><b>Update</b></Button>
          </div>
        )}
        {activeTab === 'security' && (
          <div>
            <h2>{text.security_settings}</h2>
            <p>2FA (Two Factor Authentication): {TFAStatus}</p>
            <Button variant='contained' className={StTabStyles.btnPrimary3} onClick={click2FA}><b> {Enable2FA ? text.disable_2fa : text.enable_2fa }</b></Button>
            <p>Login History:</p>
            <ul>
            {
              LoginTime.map((login, index) => (
                <li key={index}>Last login: {login}</li>
              ))
            }
             
            </ul>
          </div>
        )}
        {activeTab === 'billing' && (
          <div>
            <h2>{text.billing_settings}</h2>
            <p>Payment Methods:</p>
            <ul>
              <li>Visa **** **** 1234</li>
              <li>MasterCard **** **** 5678</li>
            </ul>
            <p>Billing History:</p>
            <ul>
              <li>Invoice #12345 - $50.00 - Paid</li>
              <li>Invoice #12346 - $75.00 - Paid</li>
            </ul>
          </div>
        )}
        {activeTab === 'preferences' && (
         <div className={StTabStyles.preferencesContainer}>
         <h2>{text.preferences}</h2>
         <div className={StTabStyles.preferencesMClass}>
         {/* <div>
         <label htmlFor="language-select" className={StTabStyles.label}>Language:</label>
         <select id="language-select" name="language" className={StTabStyles.select} onChange={(e)=> {setLanguage(e.target.value)}} value={language}>
              <option value="ar">عَرَبِيّ</option>
              <option value="es">Español</option>
              <option value="en">English</option>
              <option value="hi">हिन्दी</option>
              <option value="pt">Português</option>
              <option value="ru">русский</option>
              <option value="id">Bahasa Indonesia</option>
         </select>
</div> */}
  <div>
        <label htmlFor="theme" className={StTabStyles.label}>Theme:</label>
        <select id="theme" className={StTabStyles.select} value={theme} onChange={(e)=>{setTheme(e.target.value)}} >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
        </div>
        </div>
         <br />
         <Button variant='contained' className={StTabStyles.btnPrimary3} onClick={handleLanguageChange}><b>Update</b></Button>
       </div>
        )}
      </div>
      {modalOpen && (
        <div className={StTabStyles.modalOverlay}>
          <div className={StTabStyles.modal}>
            <h2>Change Password</h2>
            <div className={StTabStyles.passwordContainer}>
            <TextField
            type={opasswordVisible ? 'text' : 'password'}
            variant='outlined'
            key="Old Password"
            label="Old Password"
            sx={{
              width: ' calc(100% - 60px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "55px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            name='oldPassword'
            value={passwords.oldPassword}
            onChange={handlePasswordChange}
                      InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={StTabStyles.eyeButton}
            onClick={toggleOldPasswordVisibility}
            
          >
            {opasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>

           
            <div className={StTabStyles.passwordContainer}>
            <TextField
            type={passwordVisible ? 'text' : 'password'}
            variant='outlined'
            key="New Password"
            label="New Password"
            sx={{
              width: ' calc(100% - 60px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "55px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            name='newPassword'
            value={passwords.newPassword}
            onChange={handlePasswordChange}
                      InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={StTabStyles.eyeButton}
            onClick={togglePasswordVisibility}
            
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
          
            <div className={StTabStyles.passwordContainer}>
            <TextField
            type={cpasswordVisible ? 'text' : 'password'}
            variant='outlined'
            key="Confirm Password"
            label="Confirm Password"
            sx={{
              width: ' calc(100% - 60px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "55px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            name='confirmPassword'
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
                      InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={StTabStyles.eyeButton}
            onClick={toggleCPasswordVisibility}
          >
            {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
            
            <Button variant='contained' onClick={handleChangePassword} className={StTabStyles.btnPrimary2} sx={{marginRight: '10px'}}><b>Change Password</b></Button>
            <Button variant='contained' onClick={() => {setModalOpen(false); setPasswords({ newPassword: '', confirmPassword: '' , oldPassword: ''});}} className={StTabStyles.btnPrimary2}><b>Cancel</b></Button>
            {/* { errorPassword && (<Alert severity='error' >{errorPassword}</Alert>)} */}
          </div>
         
        </div>
      )}


<Modal
        open={modal2FAOpen}
        onClose={Modal2Close}
        aria-labelledby="tfa-plan-modal"
        aria-describedby="tfa-plan-description"
        closeAfterTransition
        BackdropProps={{
          style: {
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <div className={StTabStyles.modalOverlay}>
          <div ref={ref} className={StTabStyles.modal3} >
            <h2 style={{ '@media (maxWidth: 390px)': { fontSize: '22px !important'}}}>Two-factor authentication</h2>
            {processTFA == true ? (<>  
            <Typography>Enter One Time Password (OTP)</Typography><br />
            <div className={StTabStyles.otpTInputs}>
            {totp.map((data, index) => {
                return (
                  <input
                    className={StTabStyles.totpField}
                    type="text"
                    name="totp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleTChange(e.target, index)}
                    onKeyDown={(e) => handleKeyTDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  
                  />
                );
              })}
            </div><br/>
            <Button variant='contained' sx={{width: '40%', backgroundColor: '#2332B7', fontWeight: 'bold', textTransform:'capitalize','@media (max-width: 392px)': { fontSize: '14px!important'}}} onClick={TFAStatus && Enable2FA ?  handleDisableTFASubmit :  handleTFASubmit}> {TFAStatus && Enable2FA ?  "Disable 2FA" : "Enable 2FA" }</Button> <Button variant='contained' color='error' sx={{width: '40%', fontWeight: 'bold', textTransform:'capitalize','@media (max-width: 392px)': { fontSize: '14px!important'}}} onClick={Modal2Close}>Cancel</Button>
            </>) : (<> <Box sx={{border: '1px black', border: 1, padding: '10px', borderColor: 'grey.500'}}><Typography sx={{ '@media (max-width: 392px)': { fontSize: '14px !important'}}}>1. Scan QR code using Google Auhtenticator app or enter code</Typography>
            <br/>
            <Box sx={{display:'flex', flexDirection :'row', justifyContent: 'space-around'}}>
            <Box component='img' src={qrcode} width="150px"
            sx={{ '@media (max-width: 392px)': { width: '120px !important', height: '120px !important'}}}
            alt='QR code'/>
            <Typography sx={{fontWeight: 'bold', '@media (max-width: 392px)': { fontSize: '14px !important'}}}>Or</Typography>
            <Box sx={{width:'160px', border: 1, padding: '10px', borderColor: 'grey.500', '@media (max-width: 392px)': { width: '100px !important'} }}>
              <Typography sx={{wordBreak: 'break-all',  '@media (max-width: 392px)': { fontSize: '14px !important'}}}>{secretKey}</Typography>
              <Box sx={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end'}}>
              <ContentCopyIcon onClick={handleToastClick} sx={{cursor: 'pointer','&:hover': { color: 'blue'}, '@media (max-width: 392px)': { width: '20px !important', height: '20px !important'}}}/>
              </Box>
            </Box>
            </Box>
            </Box>
            <Box sx={{border: 1, padding: '10px', borderColor: 'grey.500'}}><Typography sx={{ '@media (max-width: 392px)': { fontSize: '14px !important'}}}>2. Save the recovery code in a safe place (Loss of this code may result in the loss of your account).</Typography>
            <br/>
            <Box sx={{ display: 'flex', flexDirection:'row', justifyContent:'space-between',border: 1, backgroundColor: '#eedede', padding: '8px', borderRadius: '8px', borderColor: 'grey.600'}}><Typography sx={{fontWeight:'400', '@media (max-width: 392px)': { fontSize: '14px !important'}}}>ASHASDHSDHSDHSDHSJSVBREWRHSDBSD</Typography> <ContentCopyIcon  onClick={handleToastClick} sx={{color: 'darkgrey', cursor: 'pointer',  '&:hover': { color: 'black'}, '@media (max-width: 392px)': { width: '20px !important', height: '20px !important', paddingLeft: '5px'}}}/></Box>
            </Box>
            <br/>
            <center>
            <Button variant='contained' sx={{width: '40%', backgroundColor: '#2332B7', fontWeight: 'bold', textTransform:'capitalize','@media (max-width: 392px)': { fontSize: '14px!important'}}} onClick={handleTFAClick}>Enable 2FA</Button> <Button variant='contained' color='error' sx={{width: '40%', fontWeight: 'bold', textTransform:'capitalize','@media (max-width: 392px)': { fontSize: '14px!important'}}} onClick={Modal2Close}>Cancel</Button>
            </center>
            <br/>
            <Alert severity="info" sx={{'@media (max-width: 392px)': { fontSize: '12px!important'}}}>Two-factor auhtentication (2FA) enhances the security of your account by requiring two distinct stages of idenitity verification.</Alert></>) }
           
          </div>
         
        </div>
        </Modal>
          <Modal
        open={planmodalOpen}
        onClose={handleModalClose}
        aria-labelledby="upgrade-plan-modal"
        aria-describedby="upgrade-plan-description"
        closeAfterTransition
        BackdropProps={{
          style: {
            backdropFilter: 'blur(5px)',
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="upgrade-plan-modal" variant="h6" component="h2" align="center">
            <b>Upgrade Plan</b>
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            {plans.map((plan, index) => (
              <Box
                key={index}
                sx={{
                  border: `1px solid ${selectedPlan === plan.name ? 'blue' : '#f0f0f0'}`,
                  padding: 2,
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  cursor: 'pointer',
                  backgroundColor : `${selectedPlan === plan.name ?  '#d2ecfc' : 'white'}`,
                  '&:hover': {
                    borderColor: 'blue',
                  },
                }}
                onClick={() => handlePlanSelect(plan.name)}
              >
                <Box sx={{width: '100px'}}>
                  <FaCoins style={{color: 'blue'}}  size={50}/>
                </Box>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography variant="body1" sx={{fontSize : '20px'}}><b>{plan.name}</b></Typography>
                <Typography variant="body2" sx={{fontSize : '15px'}}>{plan.credits}</Typography>
                <Typography variant="body2" sx={{fontSize : '15px'}}>{plan.price}</Typography>
                </Box>
              </Box>
            ))}
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
            {selectedPlan && (
              <Button variant="contained" color="primary">
                Continue
              </Button>
            )}
            <Button variant="contained" color="error" onClick={handleModalClose}>
              {/* <CloseIcon sx={{ mr: 1 }} /> */}
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={openToast}
        autoHideDuration={6000}
        onClose={handleToastClose}
        message="Code Copied"
      />

      
          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         {errorPassword}
        </Alert>
      </Snackbar>

      <Snackbar open={alertOpen2} autoHideDuration={6000} onClose={handleAlertClose2}>
        <Alert
          onClose={handleAlertClose2}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
         {successMessage}
        </Alert>
      </Snackbar>
    </div>
    
  );
};

export default SettingsTabs;
