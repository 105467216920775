import React, { useState, useRef, useEffect } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSettings, IoMdLogOut, IoMdSearch, IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { FaUserCircle, FaChartBar, FaCog, FaNetworkWired, FaShieldAlt } from 'react-icons/fa';
import { Card, CardContent, Typography, Avatar, Grid, Pagination, Box, TextField,Button, Alert } from '@mui/material';
import TopBar from './TopBar';
import EmStyles from './EmScan.module.css';
import companyLogo from '../assets/mlogo.png';
import { MdDashboard, MdEmail, MdMarkEmailRead } from "react-icons/md";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import URL from '../hooks/URL';
import en from '../locales/en.json';
import es from '../locales/es.json';
import ar from '../locales/ar.json';
import ru from '../locales/ru.json';
import hi from '../locales/hi.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';


const EmScan = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userNameData, setUserName] = useState('');
  const [breachData, setBreachData] = useState(null);
  const [email, setEmail] = useState('');
  const contentAreaRef = useRef(null);
  const [page, setPage] = useState(1);
  const itemsPerPage = 2;
  const [text, setText] = useState(en);
  const [lang, setLang] = useState(localStorage.getItem('DashBoardLanguage') || "en");

  const navigate = useNavigate();

  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const fetchUserData = async (token) => {
    try {
      const response = await axios.post(
        URL()+'user',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserName(response.data.name); 
      if (!response.data.name) {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      navigate('/'); 
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('SecureHashtoken'); 
    navigate('/'); 
  };

  const handleSearch = async () => {
    const token = localStorage.getItem('SecureHashtoken');

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(email))
    {
      setAlertOpen(true)
      return;
    }
    try {
      const emailBreachResponse = await axios.post(URL()+'emscan', { email },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      
      setBreachData(emailBreachResponse.data);
    } catch (error) {
      console.error('Error fetching breach data:', error);
    }
  };

  const getSourceLogo = (source) => {
    // Trim anything after the first dot in the source

    if(source.includes(" "))
    {
         return 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Database-icon.svg/529px-Database-icon.svg.png';
    }
    const trimmedSource = source.split('.')[0];
    
    // Construct the URL using the trimmed source
    const imageUrl = `https://haveibeenpwned.com/Content/Images/PwnedLogos/${trimmedSource}.png`;
    
    // Create a new Image object
    const img = new Image(imageUrl);
  
    // Set the onerror handler to return the Wikimedia icon if the image doesn't exist
    img.onerror = function() {
      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Database-icon.svg/529px-Database-icon.svg.png';
    };
  
    // Set the onload handler to return the image URL if the image exists
    img.onload = function() {
      return imageUrl;
    };
  
    // Set the src attribute to initiate the image loading
    img.src = imageUrl;
  
    // Return the image URL by default (this may return too early)
    return imageUrl;
  };
  

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const renderBreachResult = () => {
    if (!breachData) return null;

    const paginatedData = breachData.result.slice(
      (page - 1) * itemsPerPage,
      page * itemsPerPage
    );

    return (
      <center>
      <div className={EmStyles.breachResults}>
       <center><h2>Breach Count ({breachData.found})</h2></center> 
        <Box container spacing={1} sx={{display:  'flex', flexDirection: 'column', alignItems: 'center',
         
        }}>
          {paginatedData.map((item, index) => (
            <Box item key={index} sx={{width: '100%'}}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '15px',
                  padding: '10px',
                  
                }}
              >
                <img
                  src={getSourceLogo(item.sources)}
                  style={{ width: 50, marginRight: '15px', color: 'black' }}
                  alt="source logo"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Database-icon.svg/529px-Database-icon.svg.png";
                  }}
                />
                <CardContent sx={{ flex: 1 }}>
                  {item.password && (
                    <Typography variant="body2">Password: {item.password}</Typography>
                  )}
                  {item.sha1 && (
                    <Typography variant="body2">SHA1: {item.sha1}</Typography>
                  )}
                  {item.hash && (
                    <Typography variant="body2">Hash: {item.hash}</Typography>
                  )}
                  <Typography variant="body2" sx={{fontSize: '16px'}}><b>{item.sources}</b></Typography>
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
        <Pagination
          count={Math.ceil(breachData.result.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
          }}
          color="primary"
        />
      </div>
      </center>
    );
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };


  useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');
    if (!token) {
      navigate('/'); 
    } else {
      if (isTokenExpired(token)) {
        localStorage.removeItem('SecureHashtoken');
        navigate('/');
      } else {
        fetchUserData(token);
      }
    }
  }, [navigate]);

  return (
    <div className={EmStyles.dashboardPage}>
      <div className={`${EmStyles.sidebar} ${isSidebarOpen ? EmStyles.open : EmStyles.collapsed}`}>
        <div className={EmStyles.sidebarHeader}>
          <img src={companyLogo} alt="Hash Secure" className={EmStyles.companyLogo} />
          {isSidebarOpen && <h1 className={EmStyles.companyName}>HASH SECURE</h1>}
          <div className={EmStyles.sidebarToggle} onClick={toggleSidebar}>
            {isSidebarOpen ? <IoMdArrowBack /> : <IoMdArrowForward />}
          </div>
        </div>
        <div className={EmStyles.sidebarMenu}>
        <a href="/dashboard" className={EmStyles.menuItem}  >
            <MdDashboard size={30} className={EmStyles.menuIcon} />
            {isSidebarOpen && <span>{text.dashboard}</span>}
          </a>
          <a href="/portscan" className={EmStyles.menuItem} >
            <FaNetworkWired size={30} className={EmStyles.menuIcon} />
            {isSidebarOpen && <span>{text.port_scanner}</span>}
          </a>
          <a href="/phscan" className={EmStyles.menuItem}>
            <FaShieldAlt size={30} className={EmStyles.menuIcon} />
            {isSidebarOpen && <span>{text.phishing_scanner}</span>}
          </a>
          <a href="/emscan"  className={`${EmStyles.menuItem} ${EmStyles.active}`}>
            <MdEmail size={lang == 'ar' ? 60 : 30} className={EmStyles.menuIcon} />
            {isSidebarOpen && <span>{text.email_breach_scanner}</span>}
          </a>
          <a href='/emvscan' className={EmStyles.menuItem}>
            <MdMarkEmailRead size={30} className={EmStyles.menuIcon}/>
            {isSidebarOpen && <span>Email Verifier</span>}
          </a>
          <a href="/settings"  className={EmStyles.menuItem} >
            <FaCog size={30} className={EmStyles.menuIcon} />
            {isSidebarOpen && <span>{text.settings}</span>}
          </a>
        </div>
        {/* Other components go here */}
      </div>
      <div className={EmStyles.mainContent}>
        <TopBar MobileMCheck={isMobileMenuOpen} MobileMClick={toggleMobileMenu} />
        <div className={`${EmStyles.MobileMenu} ${isMobileMenuOpen ? EmStyles.MobileMenuOpen : ''}`}>
        <a  onClick={toggleMobileMenu}   href="/dashboard"><MdDashboard  className={EmStyles.menuIcon} />
             {isSidebarOpen && <span>{text.dashboard}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/portscan"><FaNetworkWired className={EmStyles.menuIcon} />
             {isSidebarOpen && <span>{text.port_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/phscan" ><FaShieldAlt className={EmStyles.menuIcon} />
             {isSidebarOpen && <span>{text.phishing_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emscan" className={EmStyles.active}><MdEmail className={EmStyles.menuIcon} />
             {isSidebarOpen && <span>{text.email_breach_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emvscan"><MdMarkEmailRead className={EmStyles.menuIcon} />
             {isSidebarOpen && <span>Email Verifier</span>}</a >
     
          <a  onClick={toggleMobileMenu}  href="/settings">  <FaCog className={EmStyles.menuIcon} />
          {isSidebarOpen && <span>{text.settings}</span>}</a >
        </div>
        <div className={EmStyles.contentArea} ref={contentAreaRef}>
          <h1 className={EmStyles.title}>Email Breach Scanner</h1>
          <div className={EmStyles.searchBarContainer}>
            <TextField
              type="text"
              variant='outlined'
              label="Enter email address"
              className={EmStyles.searchBar}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment:  <Button variant='contained' className={EmStyles.searchButton} sx={ { marginLeft: "10px",borderRadius: 28 } } onClick={handleSearch}>
                <IoMdSearch size={18} />
              </Button>
  
              }}
            />
          </div>
          {renderBreachResult()}
        </div>
      </div>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         Invalid Email Address
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmScan;
