import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useTFA from '../hooks/useTFA';
import { useLocation } from 'react-router-dom';
import useVerify from '../hooks/useVerify';

const PrivateRoute = ({ children }) => {
  let isAuthenticated = false;
  let isTFA = false;
  let isVerify = false;
 isAuthenticated = useAuth();
  isTFA = useTFA();
  const location = useLocation();
 isVerify = useVerify();
console.log(location.pathname);

console.log(isTFA);
  return isAuthenticated ? isVerify && location.pathname !== '/verify' ? <Navigate to="/verify" />  : isTFA && location.pathname !== '/tfa' ?  <Navigate to="/tfa" /> : children : <Navigate to="/" />;
};

export default PrivateRoute;
