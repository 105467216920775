import React, { useState, useEffect } from 'react';
import DStyles from './Dashboard.module.css';

import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSettings, IoMdLogOut } from 'react-icons/io';
import {  FaUserCircle } from 'react-icons/fa';
import companyLogo from '../assets/mlogo.png'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from "react-icons/io";
import Badge  from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import IconButton from '@mui/material/IconButton';
import { Typography, Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import URL from '../hooks/URL';


const TopBar = (props) => {
    const [userNameData, setUserName] = useState('');
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(props.MobileMCheck);
    const [notifications, setNotifications] = useState([]);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

  
  
    const handleLogout = () => {
        localStorage.removeItem('SecureHashtoken'); 
        localStorage.removeItem('FormUser');
        navigate('/'); 
      };
 
      const toggleUserMenu = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
        setIsNotificationOpen(false);
      };

      const toggleNotification = async () => {
        setIsNotificationOpen(!isNotificationOpen);
        setIsUserMenuOpen(false);
        const token = localStorage.getItem('SecureHashtoken');
        try{
        const response =  await axios.post(
          URL()+'read-notifications',
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
    } catch (error) {
      // console.error('Error fetching user data:', error.message);
     //   navigate('/'); // Redirect to login on error
     }

       
      };
    
      useEffect(() => {
        const token = localStorage.getItem('SecureHashtoken');
       
            fetchUserData(token);

      }, [navigate]);
    

      useEffect(()=>{
        setIsMobileMenuOpen(props.MobileMCheck);
      },[props.MobileMCheck]);
    
      const fetchUserData = async (token) => {
        try {
          const response =  await axios.post(
            URL()+'user',
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setUserName(response.data.name); 
         

          const response2 =  await axios.post(
            URL()+'get-notifications',
            {},
            { headers: { Authorization: `Bearer ${token}` } }
          );
          setNotifications(response2.data.notifications);
        setTotal(response2.data.total);
         
    
          
        } catch (error) {
         console.error('Error fetching user data:', error.message);
        //   navigate('/'); // Redirect to login on error
        }
      };

    return ( <div className={DStyles.topBar}>
       <div className={DStyles.burgerMenu} onClick={props.MobileMClick}>
            {/* <GiHamburgerMenu />  */}
            { isMobileMenuOpen ? <IoMdClose /> : <GiHamburgerMenu />}
          </div>
          <div className={DStyles.topBarCenter}>
            <img src={companyLogo} alt="Hash Secure" className={DStyles.topBarLogo} />
            <h1 className={DStyles.companyName2}>HASH SECURE</h1>
          </div>
          <div className={DStyles.userInfo}>
            <FaUserCircle className={DStyles.userIcon} />
            <span className={DStyles.userName}>Hi, {userNameData}</span>
            <IconButton aria-label="cart" onClick={toggleNotification}>
      <Badge badgeContent={total} color="primary">
        <NotificationsIcon />
      </Badge>
    </IconButton>
            <button className={DStyles.userMenuToggle} onClick={toggleUserMenu}>
              {isUserMenuOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </button>
            {isNotificationOpen && (
              <div className={DStyles.notificationDrawer} sx={{padding: '10px'}}>
                <Box sx={{display:'flex', justifyContent: 'center'}}>
                  <Typography sx={{padding: '10px', fontSize:'15px', paddingBottom: '5px', fontWeight: 'bold'}}>Notifications</Typography>
                </Box>
                <hr />
                <Box>
                <List>
              { notifications.length == 0 ? 
                ( <ListItem disablePadding >
                  <ListItemButton  >
                <ListItemText primary="No New Notifications" />
                </ListItemButton>
                </ListItem>) : (<></>)
              }
                {notifications.map((notification, index) => (
                  <>
                  <ListItem disablePadding >
            <ListItemButton  component="a" sx={{backgroundColor: 'lightblue'}}>
          <ListItemText primary={notification} />
          </ListItemButton>
          </ListItem>
          <hr />
          </>
        ))}
          {/* <ListItem disablePadding >
            <ListItemButton sx={{backgroundColor: 'lightblue'}}>
              <ListItemText primary="Your subscription credits expiring soon." />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding >
            <ListItemButton component="a" href="#simple-list" sx={{backgroundColor: 'lightblue'}}>
              <ListItemText primary="2 Vulnerabilities found in website." />
            </ListItemButton>
          </ListItem> */}
        </List>
                </Box>
                {/* <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <a href="" style={{padding: '5px'}}>View All</a>
                </Box> */}
              
              </div>
            )}
            {isUserMenuOpen && (
              <div className={DStyles.userMenu}>
                <div className={DStyles.UserMenuSub}>
                <FaUserCircle className={DStyles.userIcon2} />
                <span className={DStyles.userName2} >Hi, {userNameData}</span>
                    </div>
                <a href="/settings" className={DStyles.userMenuItem}>
                  <IoMdSettings />
                  <span>Settings</span>
                </a>
                <a href={() => false} style={{cursor: "pointer"}} className={DStyles.userMenuItem} onClick={handleLogout}>
                  <IoMdLogOut />
                  <span>Log Out</span>
                </a>
              </div>
            )}
          </div>
        </div>);
};

export default TopBar;