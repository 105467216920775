import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './Login.module.css';
import googleIcon from '../assets/google-icon2.png';  
import UserIcon from '../assets/user.png';
import MainImage from '../assets/mainimage3.png';
import { Link,useNavigate  } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import companyLogo from '../assets/logo.png';
import { IoIosArrowDropdownCircle  } from 'react-icons/io'; 
import Cookies from 'js-cookie';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import URL from '../hooks/URL';

const Login = () => {
  const navigate = useNavigate();
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [remember, setRemember] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  
  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  useEffect(() => {
    const storedUsername = Cookies.get('username');
    const storedPassword = Cookies.get('password');
    const storedRememberME = Cookies.get('rememberMe');
 

    if (storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
      setRemember(storedRememberME);
    }
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleArrowClick = () => {
    setShowLogin(true);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    localStorage.removeItem('SecureHashtoken');
    localStorage.removeItem('FormUser');
    localStorage.removeItem("emVerify");
    localStorage.removeItem("tfaChallenge");
    try {
      const response = await axios.post(URL()+'login', { email, password, remember });
      localStorage.setItem('SecureHashtoken', response.data.token);
      localStorage.setItem('FormUser','true');
      if (remember) {
        Cookies.set('username', email, { expires: 30 });
        Cookies.set('password', password, { expires: 30 });
        Cookies.set('rememberMe', remember, { expires: 30 })
      } else {
        Cookies.remove('username');
        Cookies.remove('password');
        Cookies.remove('rememberMe');
      }
      if(response.data.verified == 0)
        {
          localStorage.setItem("emVerify","true");
   
        }
        else if(response.data.TFA === true){
          localStorage.setItem("tfaChallenge","true");
         
        }
        else{
      navigate('/dashboard');
        }

        if(response.data.verified == 0)
          {
            localStorage.setItem("emVerify","true");
            navigate('/verify');
          }
          else if(response.data.TFA === true){
            localStorage.setItem("tfaChallenge","true");
            navigate('/tfa');
          }
          else{
        navigate('/dashboard');
          }
    } catch (error) {
      setErrorMessage('Invalid username or password');
      setAlertOpen(true);
    }
  };

  // const handleGetStarted = (e) => {
  //   e.preventDefault();

  //   navigate('/dashboard');
  
  // };


  const handleGoogleSignIn = () => {
    window.location.href = URL()+'auth/google';
  };
  
  return (
  	<>
  	<div className="app">
  	<div className={`${styles.leftContent} ${showLogin ? styles.hideContent : ''}`}>
     <div className={styles.logoContainer}>
            <img src={companyLogo} alt="Hash Secure" className={styles.companyLogo} />
          </div>
        <h1>Enhance Your Website’s Security</h1>
        <p>Most websites attacked while they are being exposed to common vulnerabilities.</p>
        <div className={styles.imageContainer}>
          <img src={MainImage} alt="Main Image" />
          {/* <FaArrowDown className={styles.arrowIcon} onClick={handleArrowClick} /> */}
          <div className={styles.arrowContainer} onClick={handleArrowClick}>
            <IoIosArrowDropdownCircle className={styles.arrowIcon} />
            <p className={styles.clickHereText}>Click Here</p>
          </div>
        </div>
        <div className={styles.Footer}>
          <p>Copyright © 2024 Hash Secure</p>
        </div>
      </div>
      <div className={`${styles.rightContent} ${showLogin ? styles.showContent : styles.hideContent}`}>
    <div className={styles.loginContainer}>
      <div className={styles.loginHeader}>
      <img width="70px" src={UserIcon} alt="Security Illustration" />
        <h2>Login</h2>
      </div>
      <form className={styles.loginForm} onSubmit={handleLogin}>
        <input type="text" placeholder="Email"
        value={email}
         onChange={(e) => setUsername(e.target.value)}
        />
         <div className={styles.passwordContainer}>
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="Password"
            maxLength="12"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className={styles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <center>
        <div className={styles.loginOptions}>
          {/* <label className={styles.RememberClass}>
            <input type="checkbox" />
            <span>Remember</span>
          </label> */}
          <label className={styles.checkboxContainer}>
          <input
  type="checkbox"
  checked={remember}
  onChange={() => setRemember(!remember)}
/>
      <span className={styles.customCheckbox}></span>
      <span className={styles.labelText}>Remember</span>
    </label>
          <Link to="/forget-password">Forget Password?</Link>
        </div>
        {/* <center>{errorMessage && <Alert sx={{marginBottom: '15px', width: 'calc(100% - 80px)'}} severity="error">{errorMessage}</Alert>}</center> */ }
        </center> 
        <button type="submit" className={styles.btnPrimaryL}><b>Get Started</b></button>
           <center>
        <button type="button" className={styles.btnGoogleL} onClick={handleGoogleSignIn}>
          <img width="20px" src={googleIcon} alt="Google Icon" />
          <b>Sign In With Google</b>
        </button>
        
        </center>
      </form>
      <div className={styles.createAccount}>
        <p>Don't have an account? <Link to="/signup">Create one</Link></p>
      </div>
      

      <div className={styles.Footer2}>
        <p>Copyright © 2024 Hash Secure</p>
      </div>
    </div>
    </div>
    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         {errorMessage}
        </Alert>
      </Snackbar>
    </div>
    </>
  );
};

export default Login;
