import React, { useState, useEffect } from 'react';
import {  IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import {  FaChartBar, FaCog, FaNetworkWired, FaShieldAlt  } from 'react-icons/fa';
import DStyles from './Dashboard.module.css';
import companyLogo from '../assets/mlogo.png'; // Ensure you have a company logo
import TopBar from './TopBar'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { jwtDecode }  from 'jwt-decode';
import { MdDashboard, MdEmail, MdMarkEmailRead  } from "react-icons/md";
import URL from '../hooks/URL';

import TileView from './TileView';
import GraphView from './GraphView';
import en from '../locales/en.json';
import es from '../locales/es.json';
import ar from '../locales/ar.json';
import ru from '../locales/ru.json';
import hi from '../locales/hi.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";


const Dashboard = () => {


  useEffect(()=> {
    const FirstTime = localStorage.getItem('TourSkip');
    if(!FirstTime){
    const driverObj = driver({
      animate: true,
      showProgress: true,
      showButtons: ['next', 'previous', 'close'],
      steps: [
        { element: '#Dashboard', popover: { title: 'Dashboard', description: 'Manage your entire security operations from the Dashboard. Stay updated with real-time data and oversee all critical activities in one place.', side: "left", align: 'start' }},
        { element: '#PortScanner', popover: { title: 'Port Scanner', description: 'Utilize the Port Scanner to detect open ports in your network. Identify potential vulnerabilities and secure your systems effectively.', side: "left", align: 'start' }},
        { element: '#PhishingScanner', popover: { title: 'Phishing Scanner', description: 'Use the Phishing Scanner to protect against phishing threats. Scan for suspicious links to safeguard your organization.', side: "left", align: 'start' }},
        { element: '#EmailScanner', popover: { title: 'Email Breach Scanner', description: 'Ensure your email security with the Email Breach Scanner. Detect and respond to breaches to keep your communications secure.', side: "left", align: 'start' }},
        { element: '#Settings', popover: { title: 'Settings', description: 'Customize your experience in Settings. Configure preferences, manage your account, and tailor the dashboard to your needs', side: "left", align: 'start' }},
       { popover: { title: 'Welcome to Hash Secure', description: "You're all set! You've now explored the key features of Hash Secure. Feel free to dive in and start using the dashboard to safeguard your digital environment. If you need any assistance, we're here to help. Enjoy your secure experience!" } }
      ]
    });
    
    driverObj.drive();
    localStorage.setItem('TourSkip','true');
  }

  });

  

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  
 
  const [view, setView] = useState('tile'); // tile or graph
  const [graphType, setGraphType] = useState('');

  const [text, setText] = useState(en);
  const [lang, setLang] = useState(localStorage.getItem('DashBoardLanguage') || "en");
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light' );

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const updateLanguage = (language, theme) => {
    // console.log(theme);
  if(theme == 'dark'){
    // console.log(document.body.classList.contains('dark-theme'));
    if(!document.body.classList.contains('dark-theme')){
    document.body.classList.add('dark-theme');
    }
  }
  else
  {
    document.body.classList.remove('dark-theme');
  }
    switch (language) {
      case 'es':
        setText(es);
        break;
      case 'ar':
        setText(ar);
        break;
      case 'ru':
        setText(ru);
        break;
      case 'hi':
        setText(hi);
        break;
      case 'id':
        setText(id);
        break;
      case 'pt':
        setText(pt);
        break;
      default:
        setText(en);
    }
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };


  useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');
    const newLang = localStorage.getItem('DashBoardLanguage');
    const newTheme = localStorage.getItem('theme');
    if (!token) {
      navigate('/'); 
    } else {
      if (isTokenExpired(token)) {
        localStorage.removeItem('SecureHashtoken');
        navigate('/');
      } else {
        fetchUserData(token);
      }
    }
    setLang(newLang || 'en');
    setTheme(newTheme || 'light');
    updateLanguage(lang, theme);

  }, [navigate, lang]);


  const fetchUserData = async (token) => {
    try {
      const response =  await axios.post(
        URL()+'user',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    //  setUserName(response.data.name); // Set user's name from response
    //  setEmail(response.data.email);
      if (!response.data.success) {
        throw new Error('Failed to fetch user data');
      }

      
    } catch (error) {
    //   console.error('Error fetching user data:', error.message);
    //   navigate('/'); // Redirect to login on error
    }
  };




  const handleTileClick = (type) => {
    setGraphType(type);
    setView('graph');
  };

  const handleBack = () => {
    setView('tile');
  };


  return (
    <div className={DStyles.dashboardPage}>
      <div className={`${DStyles.sidebar} ${isSidebarOpen ? DStyles.open : DStyles.collapsed}`}>
        <div className={DStyles.sidebarHeader}>
          <img src={companyLogo} alt="Hash Secure" className={DStyles.companyLogo} />
          {isSidebarOpen && <h1 className={DStyles.companyName}>HASH SECURE</h1>}
          {/* <button className={DStyles.sidebarToggle} onClick={toggleSidebar}>
            <FaBars />
          </button> */}
          <div className={DStyles.sidebarToggle} onClick={toggleSidebar}>
          {isSidebarOpen ? <IoMdArrowBack /> : <IoMdArrowForward />}
        </div>
        </div>
        <div className={DStyles.sidebarMenu}>
          <a href="/dashboard" className={`${DStyles.menuItem} ${DStyles.active}`} id="Dashboard">
            <MdDashboard size={30} className={DStyles.menuIcon} />
            {isSidebarOpen && <span>{text.dashboard}</span>}
          </a>
          <a href="/portscan" className={DStyles.menuItem}  id="PortScanner">
            <FaNetworkWired size={30} className={DStyles.menuIcon} />
            {isSidebarOpen && <span>{text.port_scanner}</span>}
          </a>
          <a href="/phscan" className={DStyles.menuItem} id="PhishingScanner">
            <FaShieldAlt size={30} className={DStyles.menuIcon} />
            {isSidebarOpen && <span>{text.phishing_scanner}</span>}
          </a>
          <a href="/emscan" className={DStyles.menuItem} id="EmailScanner">
            <MdEmail size={lang == 'ar' ? 60 : 30} className={DStyles.menuIcon} />
            {isSidebarOpen && <span>{text.email_breach_scanner}</span>}
          </a>
          <a href="/emvscan" className={DStyles.menuItem} id="EmailVerifier">
            <MdMarkEmailRead size={lang == 'ar' ? 60 : 30} className={DStyles.menuIcon} />
            {isSidebarOpen && <span>Email Verifier</span>}
          </a>

          <a href="/settings"  className={DStyles.menuItem} id="Settings">
            <FaCog size={30} className={DStyles.menuIcon} />
            {isSidebarOpen && <span>{text.settings}</span>}
          </a>
        </div>
      </div>
      <div className={DStyles.mainContent}>
     
       <TopBar MobileMCheck={isMobileMenuOpen} MobileMClick={toggleMobileMenu} />
        <div className={`${DStyles.MobileMenu} ${isMobileMenuOpen ? DStyles.MobileMenuOpen : ''}`} >
        <a  onClick={toggleMobileMenu}  className={DStyles.active} href="/dashboard"><MdDashboard  className={DStyles.menuIcon} />
             {isSidebarOpen && <span>{text.dashboard}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/portscan"><FaNetworkWired className={DStyles.menuIcon} />
             {isSidebarOpen && <span>{text.port_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/phscan"><FaShieldAlt className={DStyles.menuIcon} />
             {isSidebarOpen && <span>{text.phishing_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emscan"><MdEmail className={DStyles.menuIcon} />
             {isSidebarOpen && <span>{text.email_breach_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emvscan"><MdMarkEmailRead className={DStyles.menuIcon} />
             {isSidebarOpen && <span>Email Verifier</span>}</a >
          <a  onClick={toggleMobileMenu}  href="/settings">  <FaCog className={DStyles.menuIcon} />
          {isSidebarOpen && <span>{text.settings}</span>}</a >
          </div>
        <div className={DStyles.contentArea}>
           {/* <center><h1>Email Breach Scanner</h1></center>  */}
          {/* <div className={DStyles.searchBarContainer}>
            <input type="text" className={DStyles.searchBar} placeholder="Search..." />
            <button className={DStyles.searchButton}>
              <IoMdSearch size={18} />
            </button>
          </div> */}
           {view === 'tile' ? (
            <TileView onTileClick={handleTileClick} />
          ) : (
            <GraphView type={graphType} onBack={handleBack} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
