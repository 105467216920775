import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cstyles from './ChangePassword.module.css';
import changePasswordImage from '../assets/signup23.png'; 
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import companyLogo from '../assets/logo.png';
import axios from 'axios';
import { TextField, Alert, Button } from '@mui/material';
import URL from '../hooks/URL';

const ChangePassword = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
  
     const [cpasswordVisible, setCPasswordVisible] = useState(false);
  
    const toggleCPasswordVisibility = () => {
      setCPasswordVisible(!cpasswordVisible);
    };

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');

      if (!token) {
        navigate('/');
      }

 
      axios.post(URL()+'verify-reset-token', { token })
        .then(response => {
          if (!response.data.valid) {
            navigate('/');
          }
        })
        .catch(error => {
          navigate('/');
        });
    }, [location, navigate]);

    const handleSubmit = (e) => {
      e.preventDefault();
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!passwordRegex.test(password)) {
        setErrorMessage('Password must be at least 8 characters long and contain both upper and lower case letters.');
        return;
      }

      if (password !== confirmPassword) {
        setErrorMessage('Passwords do not match');
        return;
      }

      axios.post(URL()+'reset-password', { token, password })
        .then(response => {
          setErrorMessage('');
          setSuccessMessage('Password has been changed successfully.');
          setTimeout(() => navigate('/'), 3000);
        })
        .catch(error => {
          setErrorMessage('Failed to change password. Please try again.');
        });
    };

  return (
    <div className={Cstyles.changePasswordPage}>
      <div className={Cstyles.changePasswordContainer}>
        <div className={Cstyles.changePasswordImageContainer}>
          <img src={changePasswordImage} alt="Change Password" />
        </div>
        <div className={Cstyles.changePasswordContent}>
        <div className={Cstyles.logoContainer}>
            <img src={companyLogo} alt="Company Logo" className={Cstyles.companyLogo} />
          </div>
          <h2>Change Password</h2>
          <p>Please enter your new password below.</p>
          <form className={Cstyles.changePasswordForm} onSubmit={handleSubmit}>
          {/* <div className={Cstyles.passwordContainer}>
          <input
            type={passwordVisible ? 'text' : 'password'}
            placeholder="New Password"
            maxlength="12"
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            className={Cstyles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div> */}

<div className={Cstyles.passwordContainer}>
          <TextField
            type={passwordVisible ? 'text' : 'password'}
            variant='outlined'
            key="Password"
            label="Password"
            sx={{
              width: ' calc(100% - 70px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "45px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            
            value={password}
            onChange={(e) => setPassword(e.target.value)}
                      InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={Cstyles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {/* <div className={Cstyles.passwordContainer}>
          <input
            type={cpasswordVisible ? 'text' : 'password'}
            placeholder="Confirm New Password"
            maxlength="12"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            type="button"
            className={Cstyles.eyeButton}
            onClick={toggleCPasswordVisibility}
          >
            {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div> */}

<div className={Cstyles.passwordContainer}>
          <TextField
            type={cpasswordVisible ? 'text' : 'password'}
            variant='outlined'
            key="Confirm Password"
            label="Confirm Password"
            sx={{
              width: ' calc(100% - 70px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "45px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={Cstyles.eyeButton}
            onClick={toggleCPasswordVisibility}
          >
            {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {errorMessage && <p className={Cstyles.errorMessage}>{errorMessage}</p>}
        {successMessage && <p className={Cstyles.successMessage}>{successMessage}</p>}
            {/* <button type="submit" className={Cstyles.btnPrimary}><b>Change Password</b></button> */}

            <Button className={Cstyles.btnPrimary} type="submit" variant="contained" color="primary">
              <b>Change Password</b>
            </Button>
          </form>
        </div>
      </div>
      <div className={Cstyles.Footer}>
          <p>Copyright © 2024 Hash Secure</p>
        </div>
    </div>
  );
};

export default ChangePassword;
