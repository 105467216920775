import React, { useState, useEffect, useRef } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSettings, IoMdLogOut, IoMdSearch, IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { FaUserCircle, FaChartBar, FaCog,FaNetworkWired, FaShieldAlt  } from 'react-icons/fa';
import EmvStyles from './EmVerify.module.css';
import companyLogo from '../assets/mlogo.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose, IoMdGlobe } from "react-icons/io";
import { jwtDecode }  from 'jwt-decode';
import TopBar from './TopBar';
import { MdDashboard, MdEmail, MdMarkEmailRead } from "react-icons/md";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import en from '../locales/en.json';
import es from '../locales/es.json';
import ar from '../locales/ar.json';
import ru from '../locales/ru.json';
import hi from '../locales/hi.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import { TextField, Button, Alert } from '@mui/material';
import URL from '../hooks/URL';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';



const EmVerify = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userNameData, setUserName] = useState('');
  const [domain, setDomain] = useState('');
  const [phishingData, setPhishingData] = useState(null);
  const [phishingData2, setPhishingData2] = useState(null);
  const contentAreaRef = useRef(null);
  const [text, setText] = useState(en);
  const [lang, setLang] = useState(localStorage.getItem('DashBoardLanguage') || "en");
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light' );

  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const fetchUserData = async (token) => {
    try {
      const response = await axios.post(
        URL()+'user',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserName(response.data.name); 
      if (!response.data.name) {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      navigate('/'); 
    }
  };

  const updateLanguage = (language, theme) => {
    // console.log(theme);
  if(theme == 'dark'){
    // console.log(document.body.classList.contains('dark-theme'));
    if(!document.body.classList.contains('dark-theme')){
    document.body.classList.add('dark-theme');
    }
  }
  else
  {
    document.body.classList.remove('dark-theme');
  }
    switch (language) {
      case 'es':
        setText(es);
        break;
      case 'ar':
        setText(ar);
        break;
      case 'ru':
        setText(ru);
        break;
      case 'hi':
        setText(hi);
        break;
      case 'id':
        setText(id);
        break;
      case 'pt':
        setText(pt);
        break;
      default:
        setText(en);
    }
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };


  useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');
    const newLang = localStorage.getItem('DashBoardLanguage');
    const newTheme = localStorage.getItem('theme');
    if (!token) {
      navigate('/'); 
    } else {
      if (isTokenExpired(token)) {
        localStorage.removeItem('SecureHashtoken');
        navigate('/');
      } else {
        fetchUserData(token);
      }
    }
    setLang(newLang || 'en');
    setTheme(newTheme || 'light');
    updateLanguage(lang, theme);

  }, [navigate, lang]);


  const handleLogout = () => {
    localStorage.removeItem('SecureHashtoken'); 
    navigate('/'); 
  };

  const handleSearch = async () => {
    const token = localStorage.getItem('SecureHashtoken');
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(domain))
    {
      setAlertOpen(true)
      return;
    }
    try {
      

      const phishingScanResponse = await axios.post(URL()+'emverify', { domain },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      
      setPhishingData(phishingScanResponse.data);
      setPhishingData2(domain);
    } catch (error) {
      console.error('Error fetching scan data:', error);
    }
  };

  const renderPhishingResult = () => {
    if (!phishingData) return null;

    // const isSafe = !phishingData.isPhishing;
    // const statusColor = isSafe ? 'green' : 'red';
    // const statusText = isSafe ? 'Safe' : 'Phishing Site';

const isSafe = phishingData.includes('not exist') ? false : true;
const statusColor = isSafe ? 'green' : 'red';
const statusText = isSafe ? 'Email Exist' : 'Email Not Exist';
    return (
        <center>
      <div className={EmvStyles.phishingCard} style={{ borderColor: statusColor, justifyContent: 'center' }}>
        <div className={EmvStyles.phishingIcon} style={{ color: statusColor }}>
          {isSafe ? <IoMdGlobe /> : <IoMdClose />}
        </div>
        <div className={EmvStyles.phishingText}>
          <span style={{ color: statusColor }}>{statusText}</span>
          <p className={EmvStyles.phishingUrl}>{phishingData2}</p>
        </div>
      </div>
      </center>
    );
  };


  return (
    <div className={EmvStyles.dashboardPage}>
      <div className={`${EmvStyles.sidebar} ${isSidebarOpen ? EmvStyles.open : EmvStyles.collapsed}`}>
        <div className={EmvStyles.sidebarHeader}>
          <img src={companyLogo} alt="Hash Secure" className={EmvStyles.companyLogo} />
          {isSidebarOpen && <h1 className={EmvStyles.companyName}>HASH SECURE</h1>}
          <div className={EmvStyles.sidebarToggle} onClick={toggleSidebar}>
            {isSidebarOpen ? <IoMdArrowBack /> : <IoMdArrowForward />}
          </div>
        </div>
        <div className={EmvStyles.sidebarMenu}>
        <a href="/dashboard" className={EmvStyles.menuItem}  >
            <MdDashboard size={30} className={EmvStyles.menuIcon} />
            {isSidebarOpen && <span>{text.dashboard}</span>}
          </a>
          <a href="/portscan" className={EmvStyles.menuItem} >
            <FaNetworkWired size={30} className={EmvStyles.menuIcon} />
            {isSidebarOpen && <span>{text.port_scanner}</span>}
          </a>
          <a href="/phscan" className={EmvStyles.menuItem} >
            <FaShieldAlt size={30} className={EmvStyles.menuIcon} />
            {isSidebarOpen && <span>{text.phishing_scanner}</span>}
          </a>
          <a href="/emscan" className={EmvStyles.menuItem}>
            <MdEmail size={lang == 'ar' ? 60 : 30} className={EmvStyles.menuIcon} />
            {isSidebarOpen && <span>{text.email_breach_scanner}</span>}
          </a>
          <a href='/emvscan' className={`${EmvStyles.menuItem} ${EmvStyles.active}`}>
            <MdMarkEmailRead size={30} className={EmvStyles.menuIcon} />
            {isSidebarOpen && <span>Email Verifier</span>}
          </a>
        
          <a href="/settings"  className={EmvStyles.menuItem} >
            <FaCog size={30} className={EmvStyles.menuIcon} />
            {isSidebarOpen && <span>{text.settings}</span>}
          </a>
        </div>
      </div>
      <div className={EmvStyles.mainContent}>
      <TopBar MobileMCheck={isMobileMenuOpen} MobileMClick={toggleMobileMenu} />
        <div className={`${EmvStyles.MobileMenu} ${isMobileMenuOpen ? EmvStyles.MobileMenuOpen : ''}`}>
        <a  onClick={toggleMobileMenu}   href="/dashboard"><MdDashboard  className={EmvStyles.menuIcon} />
             {isSidebarOpen && <span>{text.dashboard}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/portscan"><FaNetworkWired className={EmvStyles.menuIcon} />
             {isSidebarOpen && <span>{text.port_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/phscan" className={EmvStyles.active}><FaShieldAlt className={EmvStyles.menuIcon} />
             {isSidebarOpen && <span>{text.phishing_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emscan"><MdEmail className={EmvStyles.menuIcon} />
             {isSidebarOpen && <span>{text.email_breach_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emvscan"><MdMarkEmailRead className={EmvStyles.menuIcon} />
             {isSidebarOpen && <span>Email Verifier</span>}</a >
          
          <a  onClick={toggleMobileMenu}  href="/settings">  <FaCog className={EmvStyles.menuIcon} />
          {isSidebarOpen && <span>{text.settings}</span>}</a >
        </div>
        <div className={EmvStyles.contentArea} ref={contentAreaRef}>
          <h1 className={EmvStyles.title}>Email Verifier</h1>
          <div className={EmvStyles.searchBarContainer}>
         
            <TextField
              type="text"
              variant='outlined'
              label="Enter email address"
              className={EmvStyles.searchBar}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              InputProps={{
                endAdornment:  <Button variant='contained' className={EmvStyles.searchButton} sx={ { marginLeft: "10px",borderRadius: 28 } } onClick={handleSearch}>
                <IoMdSearch size={18} />
              </Button>
  
              }}
            />
          </div>
          {renderPhishingResult()}
        </div>
      </div>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         Invalid Email Address
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EmVerify;
