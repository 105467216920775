import React, { useState, useEffect, useRef } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSettings, IoMdLogOut, IoMdSearch, IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { FaUserCircle, FaChartBar, FaCog,FaNetworkWired,  FaShieldAlt   } from 'react-icons/fa';
import PStyles from './PortScan.module.css';
import companyLogo from '../assets/mlogo.png';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from "react-icons/io";
import { jwtDecode }  from 'jwt-decode';
import { MdDashboard, MdEmail, MdMarkEmailRead } from "react-icons/md";
import TopBar from './TopBar'
import en from '../locales/en.json';
import es from '../locales/es.json';
import ar from '../locales/ar.json';
import ru from '../locales/ru.json';
import hi from '../locales/hi.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Tree from 'react-d3-tree';
import { Alert, Button, TextField } from '@mui/material';
import URL from '../hooks/URL';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';



const PortScan = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userNameData, setUserName] = useState('');
  const [domain, setDomain] = useState('');
  const [portsData, setPortsData] = useState(null);
  const contentAreaRef = useRef(null);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [text, setText] = useState(en);
  const [lang, setLang] = useState(localStorage.getItem('DashBoardLanguage') || "en");
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light' );

  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const updateLanguage = (language, theme) => {
    // console.log(theme);
  if(theme == 'dark'){
    // console.log(document.body.classList.contains('dark-theme'));
    if(!document.body.classList.contains('dark-theme')){
    document.body.classList.add('dark-theme');
    }
  }
  else
  {
    document.body.classList.remove('dark-theme');
  }
    switch (language) {
      case 'es':
        setText(es);
        break;
      case 'ar':
        setText(ar);
        break;
      case 'ru':
        setText(ru);
        break;
      case 'hi':
        setText(hi);
        break;
      case 'id':
        setText(id);
        break;
      case 'pt':
        setText(pt);
        break;
      default:
        setText(en);
    }
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };


  useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');
    const newLang = localStorage.getItem('DashBoardLanguage');
    const newTheme = localStorage.getItem('theme');
    if (!token) {
      navigate('/'); 
    } else {
      if (isTokenExpired(token)) {
        localStorage.removeItem('SecureHashtoken');
        navigate('/');
      } else {
        fetchUserData(token);
      }
    }
    setLang(newLang || 'en');
    setTheme(newTheme || 'light');
    updateLanguage(lang, theme);

  }, [navigate, lang]);

  useEffect(() => {
    if (contentAreaRef.current) {
      const { clientWidth, clientHeight } = contentAreaRef.current;
      setTranslate({ x: clientWidth / 2, y: clientHeight / 4 });
    }
  }, [portsData]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.post(
        URL()+'user',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserName(response.data.name); 
     
      if (!response.data.name) {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
      navigate('/'); 
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('SecureHashtoken'); 
    navigate('/'); 
  };

  const handleSearch = async () => {
    const token = localStorage.getItem('SecureHashtoken');
    const domainRegex = /^(?!https?:\/\/)((?:[a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+)($|\s|\:\d{1,5})/;
    const ipRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/;
    if(!domainRegex.test(domain) && !ipRegex.test(domain))
    {
      setAlertOpen(true)
      return;
    }
    try {
      const response = await axios.post(URL()+'pscan', { domain },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setPortsData(response.data);
    } catch (error) {
      console.error('Error fetching port data:', error);
    }
  };

  const getTreeData = () => {
    if (!portsData) return null;

    const children = portsData.openPorts.map((port) => ({
      name: `${port.port} - ${port.service}`,
      attributes: {
        status: port.status,
      },
    }));

    return {
      name: `${portsData.domain} (${portsData.ipAddress})`,
      children,
    };
  };

  return (
    <div className={PStyles.dashboardPage}>
      <div className={`${PStyles.sidebar} ${isSidebarOpen ? PStyles.open : PStyles.collapsed}`}>
        <div className={PStyles.sidebarHeader}>
          <img src={companyLogo} alt="Hash Secure" className={PStyles.companyLogo} />
          {isSidebarOpen && <h1 className={PStyles.companyName}>HASH SECURE</h1>}
          <div className={PStyles.sidebarToggle} onClick={toggleSidebar}>
            {isSidebarOpen ? <IoMdArrowBack /> : <IoMdArrowForward />}
          </div>
        </div>
        <div className={PStyles.sidebarMenu}>
          <a href="/dashboard"  className={PStyles.menuItem} >
            <MdDashboard size={30} className={PStyles.menuIcon} />
            {isSidebarOpen && <span>{text.dashboard}</span>}
          </a>
          <a href="/portscan" className={`${PStyles.menuItem} ${PStyles.active}`}>
            <FaNetworkWired size={30} className={PStyles.menuIcon} />
            {isSidebarOpen && <span>{text.port_scanner}</span>}
          </a>
          <a href="phscan" className={PStyles.menuItem}>
            <FaShieldAlt size={30} className={PStyles.menuIcon} />
            {isSidebarOpen && <span>{text.phishing_scanner}</span>}
          </a>
          <a href="emscan" className={PStyles.menuItem}>
            <MdEmail size={lang == 'ar' ? 60 : 30} className={PStyles.menuIcon} />
            {isSidebarOpen && <span>{text.email_breach_scanner}</span>}
          </a>
          <a href='emvscan' className={PStyles.menuItem}>
            <MdMarkEmailRead size={30} className={PStyles.menuIcon} />
            {isSidebarOpen && <span>Email Verifier</span>}
          </a>
          <a href="/settings"  className={PStyles.menuItem} >
            <FaCog size={30} className={PStyles.menuIcon} />
            {isSidebarOpen && <span>{text.settings}</span>}
          </a>
        </div>
      </div>
      <div className={PStyles.mainContent}>
      <TopBar MobileMCheck={isMobileMenuOpen} MobileMClick={toggleMobileMenu} />
        <div className={`${PStyles.MobileMenu} ${isMobileMenuOpen ? PStyles.MobileMenuOpen : ''}`}>
        <a  onClick={toggleMobileMenu} href="/dashboard"><MdDashboard  className={PStyles.menuIcon} />
             {isSidebarOpen && <span>{text.dashboard}</span>}</a >
             <a  onClick={toggleMobileMenu}  className={PStyles.active} href="/portscan"><FaNetworkWired className={PStyles.menuIcon} />
             {isSidebarOpen && <span>{text.port_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/phscan"><FaShieldAlt className={PStyles.menuIcon} />
             {isSidebarOpen && <span>{text.phishing_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emscan"><MdEmail className={PStyles.menuIcon} />
             {isSidebarOpen && <span>{text.email_breach_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emvscan"><MdMarkEmailRead className={PStyles.menuIcon} />
             {isSidebarOpen && <span>Email Verifier</span>}</a >
          <a  onClick={toggleMobileMenu}  href="/settings">  <FaCog className={PStyles.menuIcon} />
          {isSidebarOpen && <span>{text.settings}</span>}</a >
        </div>
        <div className={PStyles.contentArea} ref={contentAreaRef}>
          <h1 className={PStyles.title}>Open Ports Scanner</h1>
          <div className={PStyles.searchBarContainer}>
            <TextField
              type="text"
              variant='outlined'
              label="Enter domain or IP"
              className={PStyles.searchBar}
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              InputProps={{
                endAdornment:  <Button variant='contained' className={PStyles.searchButton} sx={ { marginLeft: "10px",borderRadius: 28 } } onClick={handleSearch}>
                <IoMdSearch size={18} />
              </Button>,
  
              }}
            />
           
          </div>
          {portsData && (
            <div className={PStyles.wireMapContainer}>
              <Tree
                data={getTreeData()}
                translate={translate}
                orientation="vertical"
                nodeSize={{ x: 150, y: 200 }}
                pathFunc="straight"
                // draggable={false}
                separation={{ siblings: 1, nonSiblings: 1.5 }}
                styles={{
                  nodes: {
                    node: {
                      circle: {
                        fill: 'green',
                      },
                      name: {
                        stroke: 'white',
                        strokeWidth: 0.5,
                      },
                      attributes: {
                        strokeWidth: 0.5,
                      },
                    },
                    leafNode: {
                      circle: {
                        fill: 'green',
                      },
                      name: {
                        stroke: 'black',
                        strokeWidth: 0.5,
                      },
                      attributes: {
                        strokeWidth: 0.5,
                      },
                    },
                  },
                  links: {
                    stroke: 'black',
                    strokeWidth: 2,
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         Invalid Domain or IP
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PortScan;
