import React, { useState, useEffect }from 'react';
import Vstyles from './VerifyOTP.module.css';
import { useNavigate } from 'react-router-dom';
import verifyOtpImage from '../assets/signup22.png';
import companyLogo from '../assets/logo.png';
import axios from 'axios';
import URL from '../hooks/URL';

const VerifyOTP = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [error, setError] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
      const token = localStorage.getItem('SecureHashtoken');
      const emVerify = localStorage.getItem('emVerify');
      
      if (!token) {
        navigate('/');
      }
      if(!emVerify){
        navigate('/notfound');
      }
    }, [navigate]);

    const handleChange = (element, index) => {
      if (isNaN(element.value)) return false;
  
      const value = element.value;
      setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);
  
      if (value) {
        if (element.nextSibling) {
          element.nextSibling.focus();
        }
      } else {
        if (element.previousSibling) {
          element.previousSibling.focus();
        }
      }
    };
  
    const handleKeyDown = (element, index) => {
      if (element.key === "Backspace" && otp[index] === "") {
        if (element.target.previousSibling) {
          element.target.previousSibling.focus();
        }
      }
    };


    const handleSubmit = async (e) => {
      e.preventDefault();
      const token = localStorage.getItem('SecureHashtoken');
      const TFAChallenge =  localStorage.getItem("tfaChallenge");
      try {
        const response = await axios.post(
          URL()+'verify-otp',
          { otp: otp.join('') },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if(response.data.message == "OTP verified successfully")
        {
          localStorage.removeItem('emVerify');
          if(TFAChallenge)
          {
            navigate('/tfa');
          }
          {
            navigate('/dashboard');
          }
        }
      } catch (error) {
        setError('Incorrect OTP. Please try again.');
      }
    };

    const handleResend = async () => {
      const token = localStorage.getItem('SecureHashtoken');
   
      try {
        await axios.post(
          URL()+'resend-otp',
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setError('OTP has been resent. Please check your email.');
      } catch (error) {
        setError('Failed to resend OTP. Please try again.');
      }
    };
  
    const handleLogout = () => {
      localStorage.removeItem('SecureHashtoken');
      navigate('/');
    };

  return (
    <div className={Vstyles.verifyOtpPage}>
      <div className={Vstyles.verifyOtpContainer}>
     
        <div className={Vstyles.verifyOtpContent}>
        <div className={Vstyles.logoContainer}>
            <img src={companyLogo} alt="Hash Secure" className={Vstyles.companyLogo} />
          </div>
          <h2>Verify Email</h2>
          <p>Please enter the 6-digit OTP sent to your email.</p>
          <form className={Vstyles.verifyOtpForm} autoComplete="off" onSubmit={handleSubmit}>
            <div className={Vstyles.otpInputs}>
            {otp.map((data, index) => {
                return (
                  <input
                    className={Vstyles.otpField}
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  
                  />
                );
              })}
            </div>
            <button type="submit" className={Vstyles.btnPrimary}><b>Verify</b></button>
            {error && <p className={Vstyles.errorText}>{error}</p>}
            <div className={Vstyles.links}>
              <button type="button" onClick={handleResend}>Resend</button>
              <button type="button" onClick={handleLogout}>Logout</button>
            </div>
          </form>
        </div>
        <div className={Vstyles.verifyOtpImageContainer}>
          <img src={verifyOtpImage} alt="Verify OTP" />
        </div>
      </div>
      <div className={Vstyles.Footer}>
          <p>Copyright © 2024 Hash Secure</p>
        </div>
    </div>
  );
};

export default VerifyOTP;
