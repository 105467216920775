import React, { useState,useEffect } from 'react';
import axios from 'axios';
import Sstyles from './Signup.module.css';
import googleIcon from '../assets/google-icon2.png';
import { IoMdArrowBack } from "react-icons/io";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import signupImage from '../assets/signup12.png'; 
import signupImage2 from '../assets/signup13.png'; 
import { Link,useNavigate   } from 'react-router-dom';
import companyLogo from '../assets/logo.png';
import { TextField, Alert } from '@mui/material';
import URL from '../hooks/URL';

const Signup = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({});
  const [agree, setAgree] = useState(false);
  const [btnDisabled, setbtnDisabled] =useState(false);


  const handleSignupWithEmail = () => {
    setShowForm(true);
  };

  const handleBack = () => {
    setShowForm(false);
  };

  const canBeSubmitted = () => {
    const isValid =
    formData.name?.trim().length && 
    formData.email?.trim().length && 
    formData.password?.trim().length && 
    formData.confirmPassword?.trim().length && 
      agree; 

    if (isValid) {
      
      setbtnDisabled(false);
    } else {
     
      setbtnDisabled(true);
    }

  };

  useEffect(() => canBeSubmitted());

 const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

   const [cpasswordVisible, setCPasswordVisible] = useState(false);

  const toggleCPasswordVisibility = () => {
    setCPasswordVisible(!cpasswordVisible);
  };

  // const handleGetStarted = (e) => {
  //   e.preventDefault();
  //   // Navigate to Verify OTP page
  //   navigate('/verify');
  
  // };


  const validateForm = () => {
    let formErrors = {};
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
    if(!emailRegex.test(formData.email))
    {
      formErrors.email = 'Invalid email address.';
    
    }

    if (!passwordRegex.test(formData.password)) {
      formErrors.password = 'Password must be at least 8 characters long and contain both upper and lower case letters.';
     
    }

    if (formData.password !== formData.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match.';
      
    }

    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.removeItem('SecureHashtoken');
    localStorage.removeItem('FormUser');
    localStorage.removeItem("emVerify");
    localStorage.removeItem("tfaChallenge");
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      const response = await axios.post(URL()+'signup', formData);
      localStorage.setItem('SecureHashtoken', response.data.token);
      localStorage.setItem('FormUser','true');
      localStorage.setItem("emVerify","true");
      navigate('/verify');
    } catch (error) {
      setErrors({ apiError: error.response.data.error });
    }
  };

  const handleGoogleSignUp = () => {
    window.location.href = URL()+'auth/google';
  };


  return (
    <div className={Sstyles.signupPage}>
    <div className={`${Sstyles.signupContainer} ${showForm ? Sstyles.showForm : ''}`}>
        <div className={Sstyles.signupImageContainer}>
           <img src={showForm ? signupImage2 : signupImage} alt="Signup" />
        </div>
        <div className={Sstyles.signupContent}>
          {!showForm ? (
            <>
             <div className={Sstyles.logoContainer}>
            <img src={companyLogo} alt="Hash Secure" className={Sstyles.companyLogo} />
          </div>
              <button className={Sstyles.btnPrimary} onClick={handleSignupWithEmail}><b>Sign Up with Email</b></button>
              <button className={Sstyles.btnGoogle} onClick={handleGoogleSignUp}>
                <img width="20px" src={googleIcon} alt="Google Icon" />
                <b>Sign Up with Google</b>
              </button>
              <Link className={Sstyles.LoginLink} to="/">Back to Login</Link>
                 <div className={Sstyles.Footer2}>
          <p>Copyright © 2024 example</p>
        </div>
            </>
          ) : (
            <>
            <div className={Sstyles.logoContainer2}>
              <button className={Sstyles.btnBack} onClick={handleBack}>
                <IoMdArrowBack />
              </button>
              <img src={companyLogo} alt="Company Logo" className={Sstyles.companyLogo} />
              </div>
             
              <form className={Sstyles.signupForm} onSubmit={handleSubmit}>
                <h2>Create An Account</h2>
                <center>
                <TextField type="text"
                variant='outlined'
                key="Name"
                label="Name"
                sx={{
                  width: ' calc(100% - 70px) !important',
                  marginBottom: '10px',
                  borderColor: 'black !important',
                  "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
                  "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important' },
                  "& .MuiInputBase-root": {
                    height: "45px",
                    
                  },
                  "& .Mui-focused" :  {
                    color: 'black'
                   },
                 
                }}
              required 
              InputLabelProps={{ required: false }}
                 value={formData.name}
                 onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                <TextField type="email" 
                 variant='outlined'
                 key="Email"
                 label="Email"
                 sx={{
                  width: ' calc(100% - 70px) !important',
                  marginBottom: '10px',
                  "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
                  "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                    border: 'none !important'
                   },
                  "& .MuiInputBase-root": {
                    height: "45px",
                  
                  },
                  "& .Mui-focused" :  {
                    color: 'black'
                   },
                }}
               required 
               InputLabelProps={{ required: false }}
               value={formData.email}
               onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                 {errors.email && <Alert severity='error' sx={{marginBottom: '10px'}}  className={Sstyles.errorText}>{errors.email}</Alert>}
        
                <div className={Sstyles.passwordContainer}>
          <TextField
            type={passwordVisible ? 'text' : 'password'}
            variant='outlined'
            key="Password"
            label="Password"
            sx={{
              width: ' calc(100% - 70px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "45px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            
            value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={Sstyles.eyeButton}
            onClick={togglePasswordVisibility}
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {errors.password && <Alert severity='error' sx={{marginBottom: '10px'}}  className={Sstyles.errorText}>{errors.password}</Alert>}
        <div className={Sstyles.passwordContainer}>
          <TextField
            type={cpasswordVisible ? 'text' : 'password'}
            variant='outlined'
            key="Confirm Password"
            label="Confirm Password"
            sx={{
              width: ' calc(100% - 70px) !important',
              marginBottom: '10px',
              "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
              "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                border: 'none !important'
               },
              "& .MuiInputBase-root": {
                height: "45px",
              },
              "& .Mui-focused" :  {
                color: 'black'
               },
            }}
            inputProps={{maxLength: "14"}}
            value={formData.confirmPassword}
            onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
            InputLabelProps={{ required: false }}
          />
          <button
            type="button"
            className={Sstyles.eyeButton}
            onClick={toggleCPasswordVisibility}
          >
            {cpasswordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {errors.confirmPassword && <Alert severity='error' sx={{marginBottom: '10px'}} className={Sstyles.errorText}>{errors.confirmPassword}</Alert>}
        {errors.apiError && <Alert severity='error' sx={{marginBottom: '10px'}} className={Sstyles.errorText}>{errors.apiError}</Alert>}
                {/* <label className={Sstyles.termsConditions}>
                  <input type="checkbox" required />
                  I accept the terms and conditions
                </label> */}
                  <label className={Sstyles.checkboxContainer}>
      <input type="checkbox" required name="terms" onClick={(e) => setAgree(e.target.checked)} />
      <span className={Sstyles.customCheckbox}></span>
      <span className={Sstyles.labelText}>I accept the terms and conditions</span>
    </label>
                <button type="submit" className={Sstyles.btnPrimary2} disabled={btnDisabled ? 'disabled' : undefined}><b>Get Started</b></button>
                  <div className={Sstyles.Footer2}>
          <p>Copyright © 2024 example</p>
        </div>
                </center>
              </form>

              
            </>

          )}
        </div>
      </div>
       <div className={Sstyles.Footer}>
          <p>Copyright © 2024 Hash Secure</p>
        </div>
    </div>
  );
};

export default Signup;
