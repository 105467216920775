import React from 'react';
import ntstyles from './notfound.module.css';
import notfoundimg from '../assets/notfound.png';
import { Typography, Box } from '@mui/material';

const NotFound = () => {
  return (
    <div className={ntstyles.verifyOtpPage} >
      <Box sx={{display: 'flex', height: '100%',  flexDirection: 'column', alignItems: 'center'}}>
      <Typography sx={{fontWeight: 'bold', marginTop: '20px',fontSize:'60px'}}>404</Typography>
      <Typography sx={{fontSize:'30px'}}>Page Not Found</Typography>
      <img src={notfoundimg} alt="not found" loading='lazy' width={400} height={400} />
      </Box>
    </div>
  );
};

export default NotFound;
