import React, { useState, useEffect }from 'react';
import TWStyles from './TwoFactor.module.css';
import { useNavigate } from 'react-router-dom';
import verifyOtpImage from '../assets/signup22.png';
import companyLogo from '../assets/logo.png';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import {Alert} from '@mui/material';
import axios from 'axios';
import URL from '../hooks/URL';

const TwoFactor = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const [alertOpen, setAlertOpen] = useState(false);
    const handleAlertClose = () => {
      setAlertOpen(false);
    };
    useEffect(() => {
      const token = localStorage.getItem('SecureHashtoken');
      const tfaChallenge = localStorage.getItem('tfaChallenge');
      if (!token) {
        navigate('/');
      }
      if(!tfaChallenge){
        navigate('/notfound');
      }
    }, [navigate]);

    const handleChange = (element, index) => {
      if (isNaN(element.value)) return false;
  
      const value = element.value;
      setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);
  
      if (value) {
        if (element.nextSibling) {
          element.nextSibling.focus();
        }
      } else {
        if (element.previousSibling) {
          element.previousSibling.focus();
        }
      }
    };
  
    const handleKeyDown = (element, index) => {
      if (element.key === "Backspace" && otp[index] === "") {
        if (element.target.previousSibling) {
          element.target.previousSibling.focus();
        }
      }
    };


    const handleSubmit = async (e) => {
      e.preventDefault();
      const token = localStorage.getItem('SecureHashtoken');
      try {
        const response = await axios.post(
          URL()+'TFAChallenge',
          { totp: otp.join('') },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if(response.data.codevalue == true)
        {
          localStorage.removeItem('tfaChallenge');
          const test = localStorage.getItem('tfaChallenge');
          if(!test){
              setTimeout(function(){
                navigate('/dashboard');
            }, 2000);
          }
          
          
        }else{
          
          setError('Incorrect OTP. Please try again.');
          setAlertOpen(true);
        }

        
       
      } catch (error) {
        setError('Incorrect OTP. Please try again.');
        setAlertOpen(true);
      }
    };

    const handleLogout = () => {
      localStorage.removeItem('SecureHashtoken');
      navigate('/');
    };

  return (
    <div className={TWStyles.verifyOtpPage}>
      <div className={TWStyles.verifyOtpContainer}>
     
        <div className={TWStyles.verifyOtpContent}>
        <div className={TWStyles.logoContainer}>
            <img src={companyLogo} alt="Hash Secure" className={TWStyles.companyLogo} />
          </div>
          <h2>Two Factor Authentication</h2>
          <p>Please enter One Time Password (OTP).</p>
          <form className={TWStyles.verifyOtpForm} autoComplete="off" onSubmit={handleSubmit}>
            <div className={TWStyles.otpInputs}>
            {otp.map((data, index) => {
                return (
                  <input
                    className={TWStyles.otpField}
                    type="text"
                    name="otp"
                    maxLength="1"
                    key={index}
                    value={data}
                    onChange={(e) => handleChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                  
                  />
                );
              })}
            </div>
            <button type="submit" className={TWStyles.btnPrimary}><b>Verify</b></button>
            {/* {error && <p className={TWStyles.errorText}>{error}</p>} */}
            <div className={TWStyles.links}>
              <button type="button" onClick={handleLogout}>Logout</button>
            </div>
          </form>
        </div>
        <div className={TWStyles.verifyOtpImageContainer}>
          <img src={verifyOtpImage} alt="Verify OTP" />
        </div>
      </div>
      <div className={TWStyles.Footer}>
          <p>Copyright © 2024 Hash Secure</p>
        </div>

        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert
          onClose={handleAlertClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
         {error}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TwoFactor;
