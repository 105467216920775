import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import ForgetPassword from './components/ForgetPassword';
import ChangePassword from './components/ChangePassword';
import VerifyOTP from './components/VerifyOTP';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import PortScan from './components/PortScan';
import PhScan from './components/PhScan';
import EmScan from './components/EmScan';
import PrivateRoute from './components/PrivateRoute';
import EmVerify from './components/EmVerify';
import Redirector from './components/Redirector';
import './App.css';
import TwoFactor from './components/TwoFactor';
import NotFound from './components/notfound';

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/verify" element={<VerifyOTP />} />
          <Route path="/forget-password" element={<ForgetPassword/>} />
          <Route path="/reset-password" element={<ChangePassword/>} />
          {/* <Route path="/verify" element={<PrivateRoute><VerifyOTP /></PrivateRoute>} /> */}
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
          <Route path="/portscan" element={<PrivateRoute><PortScan /></PrivateRoute>} />
          <Route path="/phscan" element={<PrivateRoute><PhScan /></PrivateRoute>} />
          <Route path="/emscan" element={<PrivateRoute><EmScan /></PrivateRoute>} />
          <Route path='/emvscan' element={<PrivateRoute><EmVerify/></PrivateRoute>}/>
          <Route path="/tfa" element={<TwoFactor />} />
          {/* <Route path='/tfa' element={<PrivateRoute><TwoFactor/></PrivateRoute>}/> */}
          <Route path="/redirect" element={<Redirector />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
