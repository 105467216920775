import React, { useState }  from 'react';
import axios from 'axios';
import { Link  } from 'react-router-dom';
import forgetPasswordImage from '../assets/signup21.png'; 
import Fstyles from './ForgetPassword.module.css';
import companyLogo from '../assets/logo.png';
import { TextField, Alert, Button } from '@mui/material';
import URL from '../hooks/URL';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');


  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post(URL()+'forget-password', { email });
        setMessage(response.data.message);
        setError('');
    } catch (error) {
        setMessage('');
    }
};

  return (
    <div className={Fstyles.forgetPasswordPage}>
      <div className={Fstyles.forgetPasswordContainer}>
    
        <div className={Fstyles.forgetPasswordImageContainer}>
          <img src={forgetPasswordImage} alt="Forget Password" />
        </div>
      <div className={Fstyles.forgetPasswordContent}>
      <div className={Fstyles.logoContainer}>
            <img src={companyLogo} alt="Hash Secure" className={Fstyles.companyLogo} />
          </div>
          <h2>Forget Password</h2>
         <p>Enter your email address and we'll send you a link to reset your password.</p>
          <form className={Fstyles.forgetPasswordForm} onSubmit={handleResetPassword}>
            {/* <input type="email" placeholder="Email" 
            onChange={(e) => setEmail(e.target.value)}
            required  /> */}

<TextField type="email" 
                 variant='outlined'
                 key="Email"
                 label="Email"
                 sx={{
                  width: ' calc(100% - 70px) !important',
                  marginBottom: '10px',
                  lineHeight: 'none',
                  "& fieldset": { borderColor: "rgba(0, 0, 0, 0.9) !important" },
                  "& .MuiInputBase-input": { color: "rgb(0, 0, 0)", width: '100% !important', margin: '0 !important',
                    border: 'none !important'
                   },
                  "& .MuiInputBase-root": {
                    height: "45px",
                  
                  },
                  "& .Mui-focused" :  {
                    color: 'black'
                   },
                }}
               required 
               InputLabelProps={{ required: false }}
               value={email}
               onChange={(e) => setEmail(e.target.value)}
                />
                 <Button variant='contained' type="submit" className={Fstyles.btnPrimary}><b>Reset Password</b></Button>
            {/* <button type="submit" className={Fstyles.btnPrimary} ><b>Reset Password</b></button> */}
          </form>
          {message && <p className={Fstyles.successMessage}>{message}</p>}
          <p className={Fstyles.backToLogin}>Remembered your password? <Link to="/">Back to Login</Link></p>

        </div>
      </div>
      <div className={Fstyles.Footer}>
          <p>Copyright © 2024 Hash Secure</p>
        </div>
    </div>
  );
};

export default ForgetPassword;