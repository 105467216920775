// TileView.js
import React from 'react';
import { FaBug, FaEnvelopeOpen, FaNetworkWired } from 'react-icons/fa';
import TStyles from './TileView.module.css';

const TileView = ({ onTileClick }) => {
  return (
    <div className={TStyles.tileContainer}>
      <div className={TStyles.tile} onClick={() => onTileClick('vulnerability')}>
        <FaBug size={40} />
        <h2>Vulnerabilities</h2>
      </div>
      <div className={TStyles.tile} onClick={() => onTileClick('emailBreach')}>
        <FaEnvelopeOpen size={40} />
        <h2>Email Breach</h2>
      </div>
      <div className={TStyles.tile} onClick={() => onTileClick('openPorts')}>
        <FaNetworkWired size={40} />
        <h2>Open Ports</h2>
      </div>
    </div>
  );
};

export default TileView;
