import React, { useState, useEffect } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup, IoMdSettings, IoMdLogOut, IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import {  FaUserCircle, FaHome, FaChartBar, FaCog, FaNetworkWired, FaShieldAlt  } from 'react-icons/fa';
import StStyles from './Settings.module.css';
import companyLogo from '../assets/mlogo.png'; 
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from "react-icons/io";
import TopBar from './TopBar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SettingsTab from './SettingsTabs';
import { jwtDecode }  from 'jwt-decode';
import { MdDashboard, MdEmail, MdMarkEmailRead } from "react-icons/md";
import en from '../locales/en.json';
import es from '../locales/es.json';
import ar from '../locales/ar.json';
import ru from '../locales/ru.json';
import hi from '../locales/hi.json';
import id from '../locales/id.json';
import pt from '../locales/pt.json';
import URL from '../hooks/URL';

const Settings = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userNameData, setUserName] = useState('');
  const [text, setText] = useState(en);
  const [lang, setLang] = useState(localStorage.getItem('DashBoardLanguage') || "en");
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light' );

  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };


  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
  };


  const updateLanguage = (language, theme) => {
    // console.log(theme);
  if(theme == 'dark'){
    // console.log(document.body.classList.contains('dark-theme'));
    if(!document.body.classList.contains('dark-theme')){
    document.body.classList.add('dark-theme');
    }
  }
  else
  {
    document.body.classList.remove('dark-theme');
  }
    switch (language) {
      case 'es':
        setText(es);
        break;
      case 'ar':
        setText(ar);
        break;
      case 'ru':
        setText(ru);
        break;
      case 'hi':
        setText(hi);
        break;
      case 'id':
        setText(id);
        break;
      case 'pt':
        setText(pt);
        break;
      default:
        setText(en);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('SecureHashtoken');

    if (!token) {
      navigate('/'); 
    } else {
      if (isTokenExpired(token)) {
        localStorage.removeItem('SecureHashtoken');
        navigate('/');
      } else {
        fetchUserData(token);
      }
    }

    updateLanguage(lang, theme);

    // Polling for localStorage changes
    const interval = setInterval(() => {
      const newLang = localStorage.getItem('DashBoardLanguage');
      if (newLang !== lang) {
        setLang(newLang || 'en');
      }
      const newTheme = localStorage.getItem('theme');
      if (newTheme !== theme) {
        setTheme(newTheme || 'light');
      }
    }, 1000); // Check every second

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [navigate, lang]);

  const fetchUserData = async (token) => {
    try {
      const response =  await axios.post(
        URL()+'user',
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setUserName(response.data.name); // Set user's name from response
      // setEmail(response.data.email);
     
      if (!response.data.success) {
        throw new Error('Failed to fetch user data');
      }

      
    } catch (error) {
    //   console.error('Error fetching user data:', error.message);
    //   navigate('/'); // Redirect to login on error
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('SecureHashtoken'); // Remove token from local storage
    navigate('/'); // Redirect to login page
  };



  return (
    <div className={`${StStyles.dashboardPage} ${SettingsTab.modalOpen ? SettingsTab.StTabStyles.blur : ''}`}>
      <div className={`${StStyles.sidebar} ${isSidebarOpen ? StStyles.open : StStyles.collapsed}`}>
        <div className={StStyles.sidebarHeader}>
          <img src={companyLogo} alt="Hash Secure" className={StStyles.companyLogo} />
          {isSidebarOpen && <h1 className={StStyles.companyName}>HASH SECURE</h1>}
          {/* <button className={StStyles.sidebarToggle} onClick={toggleSidebar}>
            <FaBars />
          </button> */}
          <div className={StStyles.sidebarToggle} onClick={toggleSidebar}>
          {isSidebarOpen ? <IoMdArrowBack /> : <IoMdArrowForward />}
        </div>
        </div>
        <div className={StStyles.sidebarMenu}>
          <a href="/dashboard" className={StStyles.menuItem}>
            <MdDashboard size={30} className={StStyles.menuIcon} />
            {isSidebarOpen && <span>{text.dashboard}</span>}
          </a>
          <a href="/portscan" className={StStyles.menuItem} >
            <FaNetworkWired size={30} className={StStyles.menuIcon} />
            {isSidebarOpen && <span>{text.port_scanner}</span>}
          </a>
          <a href="/phscan" className={StStyles.menuItem}>
            <FaShieldAlt size={30} className={StStyles.menuIcon} />
            {isSidebarOpen && <span>{text.phishing_scanner}</span>}
          </a>
          <a href="/emscan" className={StStyles.menuItem}>
            <MdEmail size={lang == 'ar' ? 60 : 30} className={StStyles.menuIcon} />
            {isSidebarOpen && <span>{text.email_breach_scanner}</span>}
          </a>
          <a href="/emvscan" className={StStyles.menuItem}>
          <MdMarkEmailRead size={30} className={StStyles.menuIcon} />
          {isSidebarOpen && <span>Email Verifier</span>}
          </a>
          <a href="/settings"  className={`${StStyles.menuItem} ${StStyles.active}`}>
            <FaCog size={30} className={StStyles.menuIcon} />
            {isSidebarOpen && <span>{text.settings}</span>}
          </a>
        </div>
      </div>
      <div className={StStyles.mainContent}>
      <TopBar MobileMCheck={isMobileMenuOpen} MobileMClick={toggleMobileMenu} />
        <div className={`${StStyles.MobileMenu} ${isMobileMenuOpen ? StStyles.MobileMenuOpen : ''}`} >
             <a  onClick={toggleMobileMenu} href="/dashboard"><MdDashboard  className={StStyles.menuIcon} />
             {isSidebarOpen && <span>{text.dashboard}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/portscan"><FaNetworkWired className={StStyles.menuIcon} />
             {isSidebarOpen && <span>{text.port_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/phscan"><FaShieldAlt className={StStyles.menuIcon} />
             {isSidebarOpen && <span>{text.phishing_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emscan"><MdEmail className={StStyles.menuIcon} />
             {isSidebarOpen && <span>{text.email_breach_scanner}</span>}</a >
             <a  onClick={toggleMobileMenu} href="/emvscan"><MdMarkEmailRead className={StStyles.menuIcon} />
             {isSidebarOpen && <span>Email Verifier</span>}</a >
          <a  onClick={toggleMobileMenu}  className={StStyles.active} href="/settings">  <FaCog className={StStyles.menuIcon} />
          {isSidebarOpen && <span>{text.settings}</span>}</a >
          </div>
        <div className={StStyles.contentArea}>
        <SettingsTab  />
        </div>
      </div>
    </div>
  );
};

export default Settings;
