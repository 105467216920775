import { useState, useEffect } from 'react';

const useVerify = () => {
  const isVerifyValid = (verifyToken) => {
    if (verifyToken) {
     return true;
    }
    return false;
  };

  

  const initialVerifyState = isVerifyValid(localStorage.getItem('emVerify'));
  const [isVerify, setIsisVerify] = useState(initialVerifyState);

  useEffect(() => {
    const verifyToken = localStorage.getItem('emVerify');
    setIsisVerify(isVerifyValid(verifyToken));
  }, []);

  return isVerify;
};

export default useVerify;
