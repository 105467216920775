import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IoMdArrowBack } from 'react-icons/io';
import { Bar, Doughnut } from 'react-chartjs-2';
import URL from '../hooks/URL';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import GStyles from './GraphView.module.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  DoughnutController,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const GraphView = ({ type, onBack }) => {
  const [graphData, setGraphData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('SecureHashtoken');
      try {
        const response = await axios.post(URL()+'graph', { graph: type },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        
        
        const data = response.data;
        console.log(response.data)
        if (data) {

          setGraphData(data);
         
        } else {
          setGraphData(null);
        }
      } catch (error) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [type]);

  const generateColors = (count) => {
    const baseColor = ['#00008B', '#0476D0', '#6699CC', '#87CEEB', '#4682B4'];
    return Array.from({ length: count }, (_, i) => baseColor[i % baseColor.length]);
  };

  const getGraphData = () => {
    if (!graphData) return {};

    return {
      labels: graphData.labels,
      datasets: [
        {
          label: graphData.label,
          data: graphData.values,
          backgroundColor: generateColors(graphData.values.length),
          barThickness: 80,
        },
      ],
    };
  };

  const renderGraph = () => {
    if (loading) {
      return <p>Loading...</p>;
    }

    if (error) {
      return <p>{error}</p>;
    }

    if (!graphData || graphData.length == 0) {
      return <p>No data to show</p>;
    }

    const data = getGraphData();
    switch (type) {
      case 'vulnerability':
      case 'openPorts':
        return <Bar data={data} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />;
      case 'emailBreach':
        return <Doughnut data={data} options={{ responsive: true, plugins: { legend: { position: 'top' } } }} />;
      default:
        return null;
    }
  };

  return (
    <div className={GStyles.graphContainer}>
      <button className={GStyles.backButton} onClick={onBack}>
        <IoMdArrowBack size={30} />
        <span>Back</span>
      </button>
      {renderGraph()}
    </div>
  );
};

export default GraphView;